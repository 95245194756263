import { InitialUserState } from './constants';
import UserTypes from './types';

const initialState = {
  userDraft: InitialUserState
};

const changeField = (draft: any, fieldName: any, value: any) => ({
  ...draft,
  [fieldName]: value,
});

export default (state = initialState, action: any = "UPDATE_USER_DRAFT") => {
  const newState = { ...state };

  switch (action.type) {
    case UserTypes.UPDATE_USER_DRAFT:
      return {
        ...state,
        userDraft: changeField(
          state.userDraft,
          action.payload.fieldName,
          action.payload.value
        ),
      };

    case UserTypes.SET_USER:
      return { ...state, userDraft: action.payload };

    default:
      return newState;
  }
};