import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { InitialNotifiesState } from '../../../redux/user/constants';
import { useEffect, useState } from 'react';
import landStyles from '../../LandingPage/src/styles';
import { customColors } from '../../../theme/palette';
import styles from '../../Dashboard/src/styles';
import StyledButton from '../../UI/StyledButton';
import { useNavigate } from 'react-router-dom';
import { useActions, useNotifications,  } from '../../../hooks/useHooks';
import Loader from '../../LandingPage/components/Loader';
import { compareDraft, } from '../../../utils/compare-draft';


export default () => {
  const [notifies, setNotifies]: any = useState(InitialNotifiesState);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { getNotifications, updateNotifications, clearUserDraft, showErrorMessage, showSuccessMessage } = useActions();
  const notificationsDraft = useNotifications();

  useEffect(() => {
    getNotifications()
      // @ts-ignore
      .then((res) => {
        setNotifies(res);
        setLoading(false);
      })
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      const newFields = compareDraft(notificationsDraft, notifies);
      if (Object.keys(newFields).length) {
        await updateNotifications(newFields);
      }
    } catch (e) {
      showErrorMessage();
    } finally {
      setLoading(false);
      showSuccessMessage();
    }
  }
  return (
    <form onSubmit={handleSubmit}>
    {!loading ? <Box>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin,
        borderRadius: "32px", border: `1px solid ${customColors.gray}`, justifyContent: "center",
        alignItems: "center", minHeight: "170px", padding: "20px 0px"
      }}>
        <Box sx={{display: "flex", width: "90%", justifyContent: "space-between",  ...styles.defaultMargin}}>
          <Typography variant="subtitle1" sx={{color: customColors.darkGray}}>Order</Typography>
          <Box sx={{display: "flex"}}>
            <Typography variant="subtitle1" sx={{color: customColors.black}}>Email</Typography>
            <Typography variant="subtitle1" sx={{color: customColors.black, marginLeft: "10px"}}>Phone</Typography>
            <Typography variant="subtitle1" sx={{color: customColors.black, marginLeft: "10px"}}>Push</Typography>
          </Box>
        </Box>
        {
          Object.entries(notifies).map(([key, value]: [string, any]) => {
            return (
              <Box sx={{display: "flex", width: "90%", justifyContent: "space-between",  ...styles.defaultMargin}}>
                <Typography variant="subtitle1" sx={{color: customColors.black}}>{value.name}</Typography>
                <Box>
                  <Checkbox checked={notifies[key].email} onChange={() =>
                    setNotifies((prevState: any) => ({
                      ...prevState,
                      [key]: {
                        ...prevState[key],
                        email: !prevState[key].email
                      }
                    }))
                  } sx={{...styles.checkbox}}/>
                  <Checkbox checked={notifies[key].txt} onChange={() =>
                    setNotifies((prevState: any) => ({
                      ...prevState,
                      [key]: {
                        ...prevState[key],
                        txt: !prevState[key].txt
                      }
                    }))
                  } sx={{marginLeft: "10px", ...styles.checkbox}}/>
                  <Checkbox checked={notifies[key].push} onChange={() =>
                    setNotifies((prevState: any) => ({
                      ...prevState,
                      [key]: {
                        ...prevState[key],
                        push: !prevState[key].push
                      }
                    }))
                  } sx={{marginLeft: "10px", ...styles.checkbox}}/>
                </Box>
              </Box>
            )
          })
        }
      </Box>
      <Box sx={{display: "flex", flexDirection: "row", maxWidth: "540px"}}>
        <StyledButton text="Save Changes" sx={{...styles.buttons}} type="submit" />
      </Box>
    </Box> : <Loader />}
    </form>
  )
}
