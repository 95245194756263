import { Box, Button, Typography } from '@mui/material';
import { customColors } from '../../../theme/palette';
import dashStyles from '../../Dashboard/src/styles';
import { useEffect, useState } from 'react';
import { UserService } from '../../../api/services/user-service';
import Loader from '../../LandingPage/components/Loader';


export default ({setCurrentBalance, sx, setModalTrigger, isCard}: any) => {
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState(0);
  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      UserService.getBalance(email)
        .then((res: any) => {
          const {balance: backBalance} = res;
          if (backBalance) {
            setBalance(backBalance);
            setCurrentBalance(backBalance);
          }
          setLoading(false);
        })
    }
  });
  const styles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
    borderRadius: "32px",
    backgroundColor: customColors.lightGray,
    minHeight: "150px",
    minWidth: "280px",
    marginBottom: "20px"
  }
  return (
      <>
      {!loading ? <Box sx={sx ? {...styles, ...sx} : {...styles}}>
        <Typography variant="subtitle1" sx={{width: "80%", height: "25%", display: "flex", alignItems: "flex-end"}}>
          Balance
        </Typography>
        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%", height: "40%", alignItems: "center"}}>
          <Typography sx={{fontSize: 32}}>$ {balance}</Typography>
          <Button sx={{...dashStyles.button, ...dashStyles.blueButton, height: "40px",
            "&:disabled": {
              color: customColors.gray
            }
          }}
          onClick={() => setModalTrigger(true)}
          disabled={!isCard}
          >
            Withdraw money
          </Button>
        </Box>
      </Box> : <Loader />}
      </>
  )
}