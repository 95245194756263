import { createContext } from 'react';
import type { Dispatch } from 'react';

type Context = {
  token: string | null;
  setToken: Dispatch<React.SetStateAction<string | null>>;
  email: string | null;
  setEmail: Dispatch<React.SetStateAction<string | null>>;
};

const AuthContext = createContext<Context>({
  token: null,
  setToken: () => {},
  email: null,
  setEmail: () => {},
});

export default AuthContext;
