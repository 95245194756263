import styles from '../../Dashboard/src/styles';
import { Box, Button, CardMedia, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker,  } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { customColors } from '../../../theme/palette';
import React, { useState } from 'react';
import { FileService } from '../../../api/services/file-service';
import { useActions } from '../../../hooks/useHooks';
import Loader from '../../LandingPage/components/Loader';
import PhoneModal from '../../Profile/components/PhoneModal';
import { UserService } from '../../../api/services/user-service';

export default ({ nameForm, changeNameForm, handleNameFormSubmit, isVerifiedTel }: any) => {
  const [firstFile, setFirstFile]: any = useState({});
  const [secondFile, setSecondFile]: any = useState({});
  const [dob, setDob]: any = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const [modalTrigger, setModalTrigger] = useState(false);
  const {showErrorMessage, showSuccessMessage, } = useActions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      const {status} = await FileService.uploadFile(firstFile.file, 'front_driver_license.png');
      const {status: status2} = await FileService.uploadFile(secondFile.file, 'back_driver_license.png');
      if (status === "200" && status2 === "200") {
        if (isVerifiedTel) {
          handleNameFormSubmit();
        } else {
          const email = localStorage.getItem('email');
          if (email) {
            await UserService.addPhone({
              phone: nameForm.phone,
              country_code: nameForm.country_code
            }, email);
            setModalTrigger(true);
          }
        }
      }
    } catch (e) {
      showErrorMessage();
    }
  };

  const handleFirstFileSelect = ({ target }: any) => {
    const [file] = target.files;
    const reader = new FileReader();

    reader.onload = () => {
      setFirstFile({
        image: reader.result, //'front_driver_license.png',
        file: file,
      });
    };

    reader.readAsDataURL(file);
    target.value = '';
  }

  const handleSecondFileSelect = ({ target }: any) => {
    const [file] = target.files;
    const reader = new FileReader();

    reader.onload = () => {
      setSecondFile({
        image: reader.result, //'back_driver_license.png',
        file: file,
      });
    };

    reader.readAsDataURL(file);
    target.value = '';
  };
  return (
    <>
      <PhoneModal modalTrigger={modalTrigger} setModalTrigger={setModalTrigger} phone={nameForm.phone}  country_code={nameForm.country_code} isRegister={true} handleRegisterSubmit={handleNameFormSubmit}/>
      {
        !loading ?
        <form onSubmit={handleSubmit}>
          <TextField required fullWidth label="First Name" variant="outlined" type="required" sx={{ ...styles.defaultMargin, ...styles.input}} value={nameForm.first_name}
                     onChange={e => changeNameForm((prevState: any) => ({...prevState, 'first_name': e.target.value }) )}/>
          <TextField required fullWidth label="Last Name" variant="outlined" type="required" sx={{...styles.input,  ...styles.defaultMargin, }} value={nameForm.last_name}
                     onChange={e => changeNameForm((prevState: any) => ({...prevState,  'last_name': e.target.value }))}/>
          <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
            <MuiPhoneNumber defaultCountry={'us'}
                            sx={{...styles.input, justifyContent: "center", marginRight: "10px"}}
                            excludeCountries={['ru', ]}
                            onlyCountries={['us']}
                            variant="outlined"
                            disableAreaCodes={true}
                            value={nameForm.country_code ? `${nameForm.country_code}+${nameForm.phone}` : nameForm.phone}
                            onChange={e => {
                              if (typeof e === 'string') {
                                const numbers = e.split(' (');
                                const regionCode = numbers[1] ? numbers[1].split(') ') : ['', ''];
                                const phoneNumbers = regionCode[1] ? regionCode[1].split('-') : ['', ''];
                                changeNameForm((prevState: any) => ({...prevState, 'phone': regionCode[0] + phoneNumbers[0] + phoneNumbers[1] }))
                                changeNameForm((prevState: any) => ({...prevState, 'country_code': numbers[0] }))
                              }
                            }}/>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="year"
                views={['year', 'month', 'day']}
                label="Date of birth"
                value={dob}
                maxDate={Date.now()}
                onChange={(newValue: any) => {
                  setDob(newValue);
                  changeNameForm((prevState: any) => ({...prevState,  'dob': (newValue ? `${newValue.getFullYear()}-${newValue.getMonth() + 1}-${newValue.getDate()}` : newValue)}));
                }}
                renderInput={(params) => <TextField sx={{...styles.input, marginLeft: "10px"}} {...params} helperText={null} />}
              />
            </LocalizationProvider>
          </Box>
          <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
            <TextField required label="Address" variant="outlined" type="required" sx={{...styles.input}} value={nameForm.address1}
                       onChange={e => changeNameForm((prevState: any) => ({...prevState,  'address1': e.target.value }))}/>
            <TextField label="Apartment (optional)" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={nameForm.address2}
                       onChange={e => changeNameForm((prevState: any) => ({...prevState,  'address2': e.target.value }))}/>
          </Box>
          <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
            <TextField required label="City" variant="outlined" type="required" sx={{...styles.input}} value={nameForm.city}
                       onChange={e => changeNameForm((prevState: any) => ({...prevState,  'city': e.target.value }))}/>
            <TextField required label="State" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={nameForm.state}
                       onChange={e => changeNameForm((prevState: any) => ({...prevState,  'state': e.target.value }))}/>
          </Box>
          <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
            <TextField required label="ZIP" type="number" variant="outlined" sx={{...styles.input}} value={nameForm.zip}
                       onChange={e => changeNameForm((prevState: any) => ({...prevState,  'zip': e.target.value }))}/>
            <TextField required label="Country" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={nameForm.country}
                       onChange={e => changeNameForm((prevState: any) => ({...prevState,  'country': e.target.value }))}/>
          </Box>
          <Box sx={{...styles.defaultMargin, width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file"
              type="file"
              onChange={(e) => {
                handleFirstFileSelect(e);
              }}
            />
            <label htmlFor="raised-button-file">
              { !firstFile.image ?
                <Button variant='outlined' component='span' sx={{
                  borderStyle: 'dashed',
                  borderColor: customColors.blue,
                  color: customColors.black,
                  width: '260px',
                  height: '160px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',

                }}>
                  <Typography sx={{ fontSize: 16 }}>Upload driver license</Typography>
                  <Typography sx={{ fontSize: 16 }}>FRONT side</Typography>
                </Button>
                :
                <CardMedia
                  sx={{
                    width: "260px",
                    height: "160px",
                  }}
                  image={firstFile.image}
                />
              }
            </label>
          </Box>
          <Box sx={{...styles.defaultMargin, width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="raised-button-file2"
              type="file"
              onChange={(e) => {
                handleSecondFileSelect(e);
              }}
            />
            <label htmlFor="raised-button-file2">
              { !secondFile.image ?
                <Button variant="outlined" component="span" sx={{
                  borderStyle: "dashed",
                  borderColor: customColors.blue,
                  color: customColors.black,
                  width: "260px",
                  height: "160px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",

                }}>
                  <Typography sx={{ fontSize: 16 }}>Upload driver license</Typography>
                  <Typography sx={{ fontSize: 16 }}>BACK side</Typography>
                </Button>
                :
                <CardMedia
                  sx={{
                    width: "260px",
                    height: "160px",
                  }}
                  image={secondFile.image}
                />
              }
            </label>
          </Box>
          <Box sx={{width: "100%", }}>
            <FormControlLabel
              control={<Checkbox />}
              label="I read the GoFlyy driver agreement and understand all terms and conditions"
              onChange={() => setIsTerms((prevState) => !prevState)}
            />
          </Box>
          <Button sx={{...styles.button, ...styles.blackButton, backgroundColor: "black",
            "&:disabled": {
              color: customColors.gray
            }
          }}
                  type="submit"
                  disabled={!isTerms || !nameForm.phone || !nameForm.phone.length || !nameForm.dob || !nameForm.first_name || !nameForm.last_name || !firstFile.image || !secondFile.image}
          >Continue</Button>
        </form> : <Loader />
      }
    </>
  )
}