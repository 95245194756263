import { Box, CardMedia, MenuItem, Modal, TextField, Typography } from '@mui/material';
import styles from "../../Dashboard/src/styles";
import { customColors } from '../../../theme/palette';
import { useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker,  } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { UserService } from '../../../api/services/user-service';
import { useActions } from '../../../hooks/useHooks';
import StyledButton from '../../UI/StyledButton';
import MuiPhoneNumber from 'material-ui-phone-number';

export default ({modalTrigger, setModalTrigger, currentCard}: any) => {
  const [cardDraft, updateCardDraft] = useState(currentCard);
  const [dobDate, setDobDate] = useState(new Date());

  const { getCurrentUser, showSuccessMessage, showErrorMessage } = useActions();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const email = localStorage.getItem('email');
    if (email) {
      const {last4, brand, ...body} = cardDraft;
      const {status, message} = await UserService.addCard(email, body);
      if (status === "200" && message !== "payout_bank_can_not_be_added") {
        getCurrentUser();
        setModalTrigger(false);
        showSuccessMessage();
      } else {
        showErrorMessage();
      }
    }
  };
  return (
    <Modal
      open={modalTrigger}
      onClose={() => setModalTrigger(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
        <Box sx={{ ...styles.modal, justifyContent: "center", minHeight: "300px", paddingTop: "10px", paddingBottom: "10px"}}>
          <Box sx={{...styles.insideModalBox, minHeight: "90%", }}>
            <form onSubmit={handleSubmit} >
            <Typography variant="h2" sx={{marginBottom: "20px", textAlign: "center"}}>
              Bank
            </Typography>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}} >
              <TextField required label="Holder First Name" variant="outlined" sx={{...styles.input, ...styles.cardInput, marginRight: "20px"}} value={cardDraft.first_name}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, first_name: e.target.value }))}/>
              <TextField required label="Holder Last Name" variant="outlined" sx={{...styles.input, ...styles.cardInput}} value={cardDraft.last_name}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, last_name: e.target.value }))}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}} >
              <MuiPhoneNumber defaultCountry={'us'}
                              sx={{...styles.input, justifyContent: "center", ...styles.cardInput, marginRight: "20px"}}
                              excludeCountries={['ru', ]}
                              onlyCountries={['us']}
                              variant="outlined"
                              disableAreaCodes={true}
                              onChange={e => {
                                if (typeof e === 'string') {
                                  const numbers = e.split(' (');
                                  const regionCode = numbers[1] ? numbers[1].split(') ') : ['', ''];
                                  const phoneNumbers = regionCode[1] ? regionCode[1].split('-') : ['', ''];
                                  updateCardDraft((prevState: any) => ({...prevState, us_phone: regionCode[0] + phoneNumbers[0] + phoneNumbers[1] }));
                                }
                              }}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  openTo="year"
                  views={['year', 'month', 'day']}
                  label="Date of birth"
                  value={dobDate}
                  maxDate={Date.now()}
                  onChange={(newValue: any) => {
                    updateCardDraft((prevState: any) => ({
                      ...prevState,
                      dob_day: newValue ? `${newValue.getDate()}` : ``,
                      dob_month: newValue ? `${newValue.getMonth() + 1}`: ``,
                      dob_year: newValue ? `${newValue.getFullYear()}`: ``,
                    }));
                    setDobDate(newValue);
                  }}
                  renderInput={(params: any) => <TextField sx={{...styles.input,  ...styles.cardInput,}} {...params} helperText={null} />}
                />
              </LocalizationProvider>

            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}} >
              <TextField required label="Routing Number" variant="outlined" type="number" sx={{...styles.input, ...styles.cardInput, marginRight: "20px"}} value={cardDraft.routing_number}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, routing_number: e.target.value }))}/>

              <TextField required label="Account Number" variant="outlined" type="number" sx={{...styles.input, ...styles.cardInput}} value={cardDraft.account_number}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, account_number: e.target.value }))}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="Address" variant="outlined" type="required" sx={{...styles.input, ...styles.cardInput, marginRight: "20px"}} value={cardDraft.address1}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, address1: e.target.value }) )}/>
              <TextField label="Second Address" variant="outlined" sx={{...styles.input, ...styles.cardInput}} value={cardDraft.address2}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, address2: e.target.value }) )}/>
            </Box>
            <TextField required fullWidth label="Last 4 digits of social security" type="number" variant="outlined" sx={{...styles.input, ...styles.defaultMargin, }} value={cardDraft.last_4}
                         onChange={e => {
                           updateCardDraft((prevState: any) => ({ ...prevState, last_4: e.target.value }));
                         }}/>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="City" variant="outlined" type="required" sx={{...styles.input, ...styles.cardInput, marginRight: "20px"}} value={cardDraft.city}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, city: e.target.value }) )}/>
              <TextField required label="State" variant="outlined" type="required" sx={{...styles.input, ...styles.cardInput}} value={cardDraft.state}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, state: e.target.value }) )}/>
            </Box>
            <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
              <TextField required label="ZIP" type="number" variant="outlined" sx={{...styles.input, ...styles.cardInput, marginRight: "20px"}} value={cardDraft.zip}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, zip: e.target.value }) )}/>
              <TextField required label="Country" variant="outlined" type="required" sx={{...styles.input,  ...styles.cardInput}} value={cardDraft.country}
                         onChange={e => updateCardDraft((prevState: any) => ({...prevState, country: e.target.value }) )}/>
            </Box>
            <StyledButton text="Add bank" type="submit" sx={{marginTop: "20px", minWidth: "200px", }}

            />
            </form>
          </Box>
        </Box>
    </Modal>
  )
}