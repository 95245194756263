import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../../theme/palette';
import typography from '../../../theme/typography';
import { breakPoints } from '../src/breakpoints';

const breaks = breakPoints(1600, 685, 685, 16, 1300);
const breaks2 = breakPoints(1300, 589, 589, 15, 800);
const breaks3 = breakPoints(500, 439, 439, 45, 300);

export default () => (
  <Box sx={{...styles.contentBox, alignItems: "center", ['@media (max-width:800px)']: {
      flexDirection: "column",
      alignContent: "center",
      alignItems: "center"
    },}}>
    <CardMedia sx={{
      height: "785px",
      width: "785px",
      borderRadius: "12px",
      ['@media (max-width:1620px)']: {
        height: "685px",
        width: "685px",
      },
      ...breaks,
      ...breaks2,
      ...breaks3
    }} image={require('../src/HIW2.png')}/>

    <Box sx={{...styles.innerBox, ...styles.sidedContent, marginLeft: "100px", maxWidth: "300px", ['@media (max-width:800px)']: {
        justifyContent: "flex-start",
        marginLeft: "0px",
        maxWidth: "60%",
        width: "60%",
        minHeight: "10%",
        marginTop: "40px",
      },}}>

      <Typography variant="h2" color={customColors.black} sx={{...styles.resizeH2}}>
        YOU DRIVE,
      </Typography>

      <Typography variant="h2" color={customColors.green} sx={{...styles.defaultMargin, ...styles.resizeH2}}>
        WE PAY
      </Typography>

      <Typography variant="h4" color={customColors.black} sx={{...styles.defaultMargin, ['@media (max-width:1300px)']: {
          fontSize: typography.subtitle1.fontSize,
        },}}>
        You’ll be making pickups from stores or distribution centers and delivering items within hours to shoppers. Other times, you’ll make returns for shoppers.
      </Typography>

      <Typography variant="h4" color={customColors.black} sx={{...styles.defaultMargin, ['@media (max-width:1300px)']: {
          fontSize: typography.subtitle1.fontSize,
        },}}>
        As a driver, you will pick up from the customer’s home or business and return an item to the store.
      </Typography>

    </Box>
  </Box>
)