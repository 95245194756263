
import styles from '../../Dashboard/src/styles';
import { Box, Button, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker, } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers'
import StyledButton from '../../UI/StyledButton';
import { useCallback, useEffect, useState } from 'react';
import { customColors } from '../../../theme/palette';
import { UserService } from '../../../api/services/user-service';
import { useActions, useUser } from '../../../hooks/useHooks';

export default ({ policyForm, changePolicyForm, handlePolicyFormSubmit }: any) => {
  const { userDraft } = useUser();
  const [vin, changeVin] = useState('');
  const [plate, changePlate] = useState('');
  const [plateState, changePlateState] = useState('');
  const [car, changeCar]: any = useState({});
  const [isCar, setIsCar] = useState(false);
  const [effective, setEffective] = useState(new Date());
  const [expiration, setExpiration] = useState(new Date());
  const {showErrorMessage, showSuccessMessage, } = useActions();

  useEffect(() => {
    if (userDraft.vehicles && userDraft.vehicles.length) {
      changeCar(userDraft.vehicles[0]);
      setIsCar(true);
    }
  });

  const searchCarByVin = useCallback(async () => {
    try {
      const email = localStorage.getItem('email');
      if (email) {
        const {status, data, ...info} = await UserService.addVehicle(email, vin, plate, plateState);
        if (status === "200" && data === "vehicle_successfully_added") {
          changeCar(info);
          setIsCar(true);
        } else {
          showErrorMessage();
        }
      }
    } catch (e) {
      showErrorMessage();
    }
  }, [vin, plate, plateState]);

  return (
    <form onSubmit={handlePolicyFormSubmit}>
      {!isCar ?
      (<Box sx={{display: "flex", flexDirection: "column", width: "100%", marginBottom: "20px", justifyContent: "center"}}>
        <TextField required fullWidth label="VIN" variant="outlined" type="required" sx={{ marginBottom: "10px", ...styles.input,}} value={vin}
                   onChange={e => changeVin(e.target.value)}/>
        <TextField required fullWidth label="Plate" variant="outlined" type="required" sx={{ marginBottom: "10px", ...styles.input,}} value={plate}
                   onChange={e => changePlate(e.target.value)}/>
        <TextField required fullWidth label="Plate State" variant="outlined" type="required" sx={{ marginBottom: "10px", ...styles.input,}} value={plateState}
                   onChange={e => changePlateState(e.target.value)}/>
        <Button
          variant="outlined"
          sx={{
            color: customColors.black,
            width: "50%",
            borderRadius: "16px",
            borderWidth: "3px"
          }}
          onClick={searchCarByVin}
          disabled={!plate.length || !plateState.length || !vin.length}
        >
          Search
        </Button>
      </Box>) :
        (
          <Box sx={{...styles.carPlate}}>
            <Typography sx={{
              width: "100%",
              fontSize: 24,
              color: "white",
            }}>{car.make} {car.model}</Typography>
            <Box>
            <Box sx={{...styles.carPlateLine}}>
              <Typography sx={{width: "40%", color: customColors.purple}}>
                VIN
              </Typography>
              <Typography sx={{width: "15%", color: customColors.purple}}>
                Year
              </Typography>
              <Typography sx={{width: "20%", color: customColors.purple}}>
                Color
              </Typography>
              <Typography sx={{width: "22%", color: customColors.purple}}>
                Number plate
              </Typography>
            </Box>
            <Box sx={{...styles.carPlateLine}}>
              <Typography sx={{width: "40%", color: customColors.white}}>
                {car.vin}
              </Typography>
              <Typography sx={{width: "15%", color: customColors.white}}>
                {car.year}
              </Typography>
              <Typography sx={{width: "20%", color: customColors.white}}>
                color
              </Typography>
              <Typography sx={{width: "22%", color: customColors.white}}>
                {car.plate}
              </Typography>
            </Box>
            </Box>
          </Box>
        )}
      <Typography variant="subtitle1">Car Insurance policy</Typography>
      <TextField required fullWidth label="Insurance Company" variant="outlined" type="required" sx={{ ...styles.defaultMargin, ...styles.input}} value={policyForm.insurer_name}
                 onChange={e => changePolicyForm((prevState: any) => ({...prevState, 'insurer_name': e.target.value }) )}/>
      <TextField required fullWidth label="Policy number" variant="outlined" type="number" sx={{...styles.input,  ...styles.defaultMargin, }} value={policyForm.number}
                 onChange={e => changePolicyForm((prevState: any) => ({...prevState,  'number': e.target.value }))}/>
      <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Effective"
            value={effective}
            disableFuture={true}
            onChange={(newValue: any) => {
              changePolicyForm((prevState: any) => ({...prevState,  'iss': newValue ? `${newValue.getFullYear()}-${newValue.getMonth() + 1}-${newValue.getDate()}` : newValue}));
              setEffective(newValue);
            }}
            renderInput={(params) => <TextField sx={{...styles.input, marginRight: "10px"}} {...params} helperText={null} />}
          />
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Expiration"
            value={expiration}
            disablePast={true}
            onChange={(newValue: any) => {
              changePolicyForm((prevState: any) => ({...prevState,  'exp': newValue ? `${newValue.getFullYear()}-${newValue.getMonth() + 1}-${newValue.getDate()}` : newValue}));
              setExpiration(newValue);
            }}
            renderInput={(params) => <TextField sx={{...styles.input}} {...params} helperText={null} />}
          />
        </LocalizationProvider>
      </Box>
      <StyledButton text="Next step" sx={{minWidth: "240px"}} type="submit"
        disabled={!policyForm.iss || !policyForm.iss.length ||
        !policyForm.exp || !policyForm.exp.length ||
        !policyForm.number || !policyForm.number.length ||
        !policyForm.insurer_name || !policyForm.insurer_name.length ||
        !isCar}
      />
    </form>
  )
}