import styles from "../Dashboard/src/styles";
import { Box, Button, Tab, TextField, Typography } from '@mui/material';
import { InitialUserState } from '../../redux/user/constants';
import { useEffect, useState } from 'react';
import { customColors } from '../../theme/palette';
import landStyles from"../LandingPage/src/styles";
import StyledButton from '../UI/StyledButton';
import UserInfo from './components/UserInfo';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import NotificationsSettings from './components/NotificationsSettings';
import Payment from './components/Payment';
import { useAuthRedirect } from '../../utils/use-auth';
import { UserService } from '../../api/services/user-service';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from '../Header/DashboardHeader';

export default () => {
  useAuthRedirect();
  const [tabValue, setTabValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const email = localStorage.getItem('email');
    const token = localStorage.getItem('token');
    if (!email || !token) {
      const StorageEvent = new Event('storage');
      window.dispatchEvent(StorageEvent);
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      UserService.getBackgroundCheckStatus(email)
          // @ts-ignore
        .then((res) => {
          if (res.message === null
            || res.message === 'Pending' || res.message === 'Decline')
          {
            navigate('/identification');
          }
        })
    }
  }, []);

  return (
  <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
  <DashboardHeader />
  <Box sx={{ ...styles.box }}>
    <Box sx={{ ...styles.contentBox, }}>
      <Typography variant='h3' sx={{...styles.defaultMargin}}>
        Profile
      </Typography>
      <TabContext value={tabValue}>
        <Box sx={{display: "flex",
          "& .MuiTabs-indicator": {
            display: "none"
          }}}>
          <TabList onChange={(e, value) => setTabValue(value)} sx={{borderRadius: "16px", backgroundColor: customColors.lightGray,

          }}>
            <Tab label="Information" value="1" sx={{...styles.tab}}/>
            <Tab label="Payment" value="2" sx={{...styles.tab}}/>
            <Tab label="Notifications" value="3" sx={{...styles.tab}}/>
          </TabList>
        </Box>
        <TabPanel value="1"><UserInfo /></TabPanel>
        <TabPanel value="2"><Payment /></TabPanel>
        <TabPanel value="3"><NotificationsSettings /></TabPanel>
      </TabContext>
    </Box>
  </Box>
  </Box>
)}