import { Box, Button, CardMedia, Link, TextField, Typography } from '@mui/material';
import styles from "./src/styles";
import React, { useState } from 'react';
import StyledButton from '../UI/StyledButton';
import { customColors } from '../../theme/palette';
import GFIcon from '../UI/GFIcon';
import { testEmail } from './constants';
import { useNavigate } from 'react-router-dom';
import { SignService } from '../../api/services/sign-service';

export default () => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const resp = await SignService.resetPassword(email);
  };

  return (
    <Box sx={{...styles.generalBox}}>
      <Box sx={{...styles.contentBox}}>
        <Button sx={{marginTop: "40px"}} onClick={() => navigate('/')}>
          <GFIcon />
        </Button>
        <Box sx={{...styles.loginBox,}}>
          <form onSubmit={handleSubmit} style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <Typography sx={{fontSize: 32, marginBottom: "20px", fontWeight: 'bold'}}>Forgot password?</Typography>
            <Typography sx={{fontSize: 16, marginBottom: "20px",}}>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</Typography>

            <TextField fullWidth required label="Email address" variant="outlined" sx={{...styles.input,}} onChange={e => {
              setEmail(e.target.value);
              setValidEmail(testEmail(e.target.value));
            }}/>
            <Box sx={{...styles.buttonBox}}>
              <StyledButton text="Send reset information" type="submit" sx={{...styles.signUpButtons}} disabled={!validEmail}/>
              <Button onClick={() => navigate('/login')} variant="outlined" sx={{height: "50px", width: "45%", borderRadius: "20px", color: customColors.black, ['@media (max-width:640px)']: {
                  width: "100%",
                  maxWidth: "100%"
                },}}>
                Back to log in
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  )
}