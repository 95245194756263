import { Box } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SignService } from '../../api/services/sign-service';

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const email = searchParams.get("email");
    const code = searchParams.get("code");
    if (email && code) {
      SignService.verifyEmail(email, code)
        .then(() => {
          navigate('/login');
        })
        .catch((e) => {
          console.log(e);
          navigate('/login');
        })
    }
  }, []);
  return (
    <Box>

    </Box>
  )
}