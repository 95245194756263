import { Box, MenuItem, TextField, Typography } from '@mui/material';
import styles from "../Dashboard/src/styles";
import { TICKET_TYPES } from './src/constants';
import { useState } from 'react';
import StyledButton from '../UI/StyledButton';
import { SupportService } from '../../api/services/support-service';
import { useNavigate } from 'react-router-dom';
import { useActions } from '../../hooks/useHooks';
import DashboardHeader from '../Header/DashboardHeader';


export default () => {
  const [ticketDraft, setTicketDraft] = useState({
    type: "package",
    details: "",
  });
  const navigate = useNavigate();
  const {getTickets,} = useActions();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const {status, message} = await SupportService.createTicket(ticketDraft);
    if (status === "200" && message === "ticket_successfully_created") {
      getTickets()
        // @ts-ignore
        .then(() => {
          navigate('/support')
        });
    }
  }
  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
      <DashboardHeader />
    <Box sx={{ ...styles.box, justifyContent: "flex-start" }}>
      <Box sx={{width: "90%", }}>
      <Box sx={{ ...styles.contentBox, width: "50%"}}>
        <form onSubmit={handleSubmit}>
        <Typography variant="h2" sx={{marginBottom: "30px", }}>
          New ticket
        </Typography>
        <TextField
          select
          required
          fullWidth
          sx={{...styles.dropDownInput, ...styles.input, marginBottom: "30px",}}
          value={ticketDraft.type}
          label="Type"
          onChange={(e) => setTicketDraft((prevState) => ({...prevState, type: e.target.value}))}
        >
          {
            TICKET_TYPES.map((type: any) => {
              return (
                <MenuItem key={type} value={type.toLowerCase()} sx={{fontSize: 16}}>
                  {type}
                </MenuItem>
              )
            })
          }
        </TextField>
        <TextField
          multiline={true}
          rows={10}
          required
          label="Details"
          fullWidth
          variant="filled"
          sx={{
            marginBottom: "30px",
            height: "130px",
            ...styles.input,
            fontSize: "16px",
            borderRadius: "16px",
          }}
          inputProps={{
            style: {
              height: "130px",
              fontSize: "16px",
            }
          }}
          onChange={(e) => setTicketDraft((prevState) => ({...prevState, details: e.target.value}))}
          />
          <StyledButton text="Create ticket" sx={{
            minWidth: "240px",
            marginTop: "50px"
          }} type="submit" />
        </form>
      </Box>
      </Box>
    </Box>
    </Box>
  )
}