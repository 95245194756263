import { Avatar, Box, Button, Typography } from '@mui/material';
import styles from '../src/styles';
import { useCallback, useMemo, useState } from 'react';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { customColors } from '../../../theme/palette';
import typography from '../../../theme/typography';

export default ({tests}: any) => {
  const [index, setIndex] = useState(0);
  const next = useCallback(() => {
    setIndex(prev => prev + 1);
  }, []);
  const previous = useCallback(() => {
    setIndex(prev => prev - 1);
  }, []);
  const TestSlider = useMemo(() => {
    let title = ``;
    let message = ``;
    let name = ``;
    let date = ``;
    let picture = ``;
    if (tests[index]) {
      title = tests[index].title;
      message = tests[index].message;
      name = tests[index].name;
      date = tests[index].date;
      picture = tests[index].picture;
    }
    const isNext = !tests[index + 1];
    const isPrev = !tests[index - 1];
    return () => (
    <Box sx={{...styles.testsBox}}>
      <Button sx={{...styles.sliderButtons, color: customColors.black}} onClick={previous} disabled={isPrev}>
        <NavigateBefore />
      </Button>
      <Box sx={{...styles.reviewBox, }}>
        <Typography sx={{
          ['@media (max-width:1300px)']: {
            fontSize: typography.subtitle2.fontSize
          }, fontWeight: "bold"}} variant="subtitle1">{title}</Typography>
        <Typography sx={{...styles.defaultMargin, height: "20%", minHeight: "100px",
          ['@media (max-width:1300px)']: {
            fontSize: typography.subtitle1.fontSize
          },}} variant="h4">{message}</Typography>
        <Box sx={{...styles.ceoBox}}>
          <Avatar style={{height: "56px", width: "56px", marginRight: "10px"}} src={picture}/>
          <Box>
            <Typography variant="h4">{name}</Typography>
            <Typography variant="subtitle2" sx={{color: customColors.gray}}>{date}</Typography>
          </Box>
        </Box>
      </Box>
      <Button sx={{...styles.sliderButtons, color: customColors.black}} onClick={next} disabled={isNext}>
        <NavigateNext />
      </Button>
    </Box>
  )}, [index, next, previous, tests]);

  return (
  <Box sx={{...styles.contentBox, justifyContent: "center"}} className="Testimonials">
    <Box sx={{...styles.innerBox, ...styles.testimonials}}>
      <Typography variant="h2" color={customColors.white} sx={{fontWeight: "bold", ...styles.resizeH2,
        ['@media (max-width:760px)']: {
          color: "black"
        },}}>
        Testimonials
      </Typography>
      <TestSlider />
    </Box>
  </Box>
)};