import {
  Box,
  Button,
  CardMedia,
  Checkbox,
  FormControlLabel, IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import styles from "./src/styles";
import React, { useState } from 'react';
import StyledButton from '../UI/StyledButton';
import { customColors } from '../../theme/palette';
import GFIcon from '../UI/GFIcon';
import { useNavigate } from 'react-router-dom';
import { SignService } from '../../api/services/sign-service';
import { testEmail } from './constants';
import { useAuthRedirect } from '../../utils/use-auth';
import { CREATE_ACC_RESPONSES } from '../../api/constants';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const USER_TEXTS = {
  primary: `Being a GoFlyy driver allows you to take charge and decide when and how you want to earn.
Just create an account to be connected to the millions of customers on the GoFlyy platform.`,
  ACCOUNT_CREATED: `Account successfully created, please verify you email.`,
  EMAIL_TAKEN: `Email below is already registered in GoFlyy service.`
};

export default () => {
  useAuthRedirect(false);
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [repeatPass, setRepeatPass] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [underText, setUnderText] = useState(USER_TEXTS.primary);
  const [zip, setZip] = useState('');
  const [validatedZip, setValidatedZip] = useState(false);
  const [validZip, setValidZip] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSubmit = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    const {status, data} = await SignService.createAccount(email, pass);
    if (data.message === CREATE_ACC_RESPONSES.SUCCESS) {
      setRegistered(true);
      setUnderText(USER_TEXTS.ACCOUNT_CREATED);
    } else if (data.message === CREATE_ACC_RESPONSES.EMAIL_ALREADY_TAKEN) {
      setUnderText(USER_TEXTS.EMAIL_TAKEN);
    }
  }
  return (
    <Box sx={{...styles.generalBox}}>
      <Box sx={{...styles.contentBox}}>
        <Button sx={{marginTop: "40px"}} onClick={() => navigate('/')}>
          <GFIcon />
        </Button>
        <Box sx={{...styles.loginBox, paddingTop: "20px", paddingBottom: "20px"}}>
          <form onSubmit={handleSubmit} style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <Typography sx={{fontSize: 32, marginBottom: "20px", fontWeight: 'bold'}}>Sign up to GoFlyy</Typography>
            <Typography variant="subtitle1" sx={registered ? {color: customColors.green} : {color: customColors.black}}>
              {underText}
            </Typography>
            {!registered &&
              (
                <>
                <TextField required label="Email address" variant="outlined" sx={{...styles.input,}} onChange={e => {
                  setEmail(e.target.value);
                  setValidEmail(testEmail(e.target.value));
                }}/>
                <TextField type={showPassword ? "text" : "password"} required label="Password" variant="outlined" sx={{...styles.input, marginTop: "10px"}} onChange={e => setPass(e.target.value)}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         onClick={handleClickShowPassword}
                         onMouseDown={handleMouseDownPassword}
                       >
                         {showPassword ? <Visibility /> : <VisibilityOff />}
                       </IconButton>
                     </InputAdornment>
                   )
                 }}
                />
                <TextField type={showPassword ? "text" : "password"} required label="Repeat Password" variant="outlined" sx={{...styles.input, marginTop: "10px"}} onChange={e => setRepeatPass(e.target.value)}
                 InputProps={{
                   endAdornment: (
                     <InputAdornment position="end">
                       <IconButton
                         aria-label="toggle password visibility"
                         onClick={handleClickShowPassword}
                         onMouseDown={handleMouseDownPassword}
                       >
                         {showPassword ? <Visibility /> : <VisibilityOff />}
                       </IconButton>
                     </InputAdornment>
                   )
                 }}
                />
                <FormControlLabel sx={{textAlign: "start", marginBottom: "20px"}} control={<Checkbox />} label="I read and agree with the Terms of Services." onChange={() => setAccepted(prevState => !prevState)} />
                <Box sx={{...styles.buttonBox, justifyContent: "center"}}>
                  <StyledButton onClick={() => {
                  }} text='Create account' type='submit' sx={{ ...styles.signUpButtons }}
                                                             disabled={!(repeatPass === pass) || !validEmail || !accepted} />
                  <Button onClick={() => navigate('/login')} variant="outlined" sx={{height: "50px", width: "45%", borderRadius: "20px", color: customColors.black, ['@media (max-width:640px)']: {
                      width: "100%",
                      maxWidth: "100%"
                    },}}>
                    Back to log in
                  </Button>
                </Box>
                </>
              )}
          </form>
        </Box>
      </Box>
    </Box>
  )
}