import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../../theme/palette';
import typography from '../../../theme/typography';
import { breakPoints } from '../src/breakpoints';

const breaks = breakPoints(1600, 685, 685, 16, 1300);
const breaks2 = breakPoints(1300, 589, 589, 15, 800);
const breaks3 = breakPoints(500, 439, 439, 45, 300);

export default () => (
  <Box sx={{...styles.contentBox, flexWrap: "wrap-reverse", alignItems: "center", ['@media (max-width:800px)']: {
      flexDirection: "column-reverse",
      alignContent: "center",
      alignItems: "center"
    },}} className="Benefits">
    <Box sx={{...styles.innerBox, ...styles.sidedContent, minWidth: "20%", maxWidth: "450px", marginRight: "100px", ['@media (max-width:800px)']: {
        justifyContent: "flex-start",
        marginRight: "0px",
        maxWidth: "60%",
        width: "60%",
        minHeight: "10%",
        marginTop: "40px",
      },}}>
      <Typography variant="h2" color={customColors.black} sx={{...styles.resizeH2}}>YOU'RE</Typography>
      <Typography variant="h2" color={customColors.green} sx={{...styles.defaultMargin, ...styles.resizeH2}}>THE BOSS</Typography>
      <Typography variant="h4" color={customColors.black} sx={{...styles.defaultMargin, ['@media (max-width:1300px)']: {
          fontSize: typography.subtitle1.fontSize,
        },}}>
        As an independent contractor, you have the flexibility and freedom to drive whenever you want, wherever you want.
      </Typography>
      <Typography variant="h4" color={customColors.black} sx={{...styles.defaultMargin, ['@media (max-width:1300px)']: {
          fontSize: typography.subtitle1.fontSize,
        },}}>
        You can make it full-time, part-time, or once in a while - and dash practically anywhere. It's a great alternative to seasonal, temporary, and part-time work.
      </Typography>
    </Box>
    <CardMedia sx={{
      height: "785px",
      width: "785px",
      borderRadius: "12px",
      ['@media (max-width:1620px)']: {
        height: "685px",
        width: "685px",
      },
      ...breaks,
      ...breaks2,
      ...breaks3
    }} image={require('../src/HIW.png')}/>
  </Box>
)