import styles from '../src/styles';
import { Box, Typography } from '@mui/material';
import P3 from '../src/P3.png';
import React from 'react';
import { customColors } from '../../../theme/palette';
import typography from '../../../theme/typography';


export default () => (
  <Box sx={{...styles.contentBox, }}>
    <Box sx={{...styles.innerBox, backgroundImage: `url(${P3})`, ...styles.weValueBox}}>
      <Box sx={{maxWidth: "28%", margin: "5%", ['@media (max-width:1300px)']: {
          maxWidth: "60%"
        },}}>
        <Typography variant="h2" color={customColors.black} sx={{fontWeight: "bold", ...styles.resizeH2}}>
          WE VALUE
        </Typography>

        <Typography variant="h2" color={customColors.black} sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH2}}>
          YOUR TIME
        </Typography>

        <Typography variant="h4" color={customColors.black}>
          We work with the exclusive market, the fashion and health industry. We value your time better than anyone else.
        </Typography>
      </Box>
    </Box>
  </Box>
);