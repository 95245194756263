import axios from 'axios';
import { apiRootUrl } from './constants';


const api = axios.create({
  baseURL: apiRootUrl,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  // @ts-ignore
  config.headers.token = token ? token : '';
  // @ts-ignore
  config.headers["Content-Type"] = "multipart/form-data";
  return config;
});

export default api;