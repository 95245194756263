import styles from '../src/styles';
import { customColors } from '../../../theme/palette';
import { Box, Button, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import React from 'react';
import R5 from '../src/hand_driver.png';
import typography from '../../../theme/typography';
import { ReactComponent as Apple } from "../src/assets/Apple.svg";
import { ReactComponent as GooglePlay } from "../src/assets/GP.svg";
import { ReactComponent as Windows } from "../src/assets/Windows.svg";
import { useNavigate } from 'react-router-dom';



export default ({appLinks}: any) => {
  const navigate = useNavigate();
  return (
    <Box sx={{...styles.contentBox, ...styles.expBox,}}>
      <Box sx={{ backgroundImage: `url(${R5})`, ...styles.expWithBackBox}}>
        <Box sx={{...styles.innerBox, minWidth: "300px", maxWidth: "700px", marginLeft: "5%", ['@media (max-width:1300px)']: {
            justifyContent: "flex-start",
            alignItems: "center",
            textAlign: "center",
            paddingTop: "50px"
          },
          ['@media (max-width:800px)']: {
            maxWidth: "500px"
          },
        }}>
          <Typography variant="h1" color={customColors.white} sx={{...styles.defaultMargin, ...styles.resizeH1}}>
            WE VALUE YOUR TIME BETTER THAN ANYONE ELSE
          </Typography>
          <Box sx={{display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", ['@media (max-width:800px)']: {
              flexDirection: "column"
            },}}>
            <Typography variant="subtitle1" color={customColors.white} sx={{maxWidth: "250px", marginRight: "20px", ['@media (max-width:800px)']: {
                ...styles.defaultMargin,
                marginRight: "0px"
              },}}>
              Become a driver in a GoFlyy
              one-day delivery service
            </Typography>
            <StyledButton sx={{...styles.signUp, minWidth: "150px", minHeight: "50px"}} text="Start earning"
                          onClick={() => navigate('/registration')}
            />
          </Box>
          <Box sx={{...styles.defaultMargin, marginTop: "5%"}}>
            { appLinks.ios_app_link ?
              <Button href={appLinks.ios_app_link}>
                <Apple style={{ ...styles.imageIcon }} />
              </Button>
              : null}
            { appLinks.android_app_link ?
              <Button href={appLinks.android_app_link}>
                <GooglePlay style={{ ...styles.imageIcon }} />
              </Button>
              : null}
            { appLinks.win_app_link ?
              <Button href={appLinks.win_app_link}>
                <Windows style={{ ...styles.imageIcon }} />
              </Button>
              : null}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}