interface IUpdateUser {
  address: {
    country?: string;
    city?: string;
    state?: string;
    zip?: string;
    address1?: string;
    address2?: string;
    details?: string;
  },
  phone: {
    country_code?: string;
    phone?: string;
  },
  contact: {
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    title?: string;
    dob?: string;
    sex?: string;
    ssn?: string;
  }
}

export const updateUserBody = (body: any) => {
  const result: IUpdateUser = {
    address: {},
    phone: {},
    contact: {}
  }
  if (body.country) {
    result.address.country = body.country;
  }
  if (body.city) {
    result.address.city = body.city;
  }
  if (body.state) {
    result.address.state = body.state;
  }
  if (body.zip) {
    result.address.zip = body.zip;
  }
  if (body.address1) {
    result.address.address1 = body.address1;
  }
  if (body.address2) {
    result.address.address2 = body.address2;
  }
  if (body.address_details) {
    result.address.details = body.address_details;
  }
  if (body.country_code) {
    result.phone.country_code = body.country_code;
  }
  if (body.phone) {
    result.phone.phone = body.phone;
  }
  if (body.first_name) {
    result.contact.first_name = body.first_name;
  }
  if (body.middle_name) {
    result.contact.middle_name = body.middle_name;
  }
  if (body.last_name) {
    result.contact.last_name = body.last_name;
  }
  if (body.title) {
    result.contact.title = body.title;
  }
  if (body.dob) {
    result.contact.dob = body.dob;
  }
  if (body.gender) {
    result.contact.sex = body.gender;
  }
  if (body.ssn) {
    result.contact.ssn = body.ssn;
  }
  return result;
}