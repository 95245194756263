import UserTypes from './types';
import { UserService } from '../../api/services/user-service';
import { InitialUserState } from './constants';
import { SnackbarTypes } from '../snackbar/types';

export const updateUserDraft = (fieldName: any, value: any) => ({
  type: UserTypes.UPDATE_USER_DRAFT,
  payload: { fieldName, value },
});

export const clearUserDraft = () => (dispatch: any) => {
  dispatch({
    type: UserTypes.SET_USER,
    payload: InitialUserState
  })
};

export const getCurrentUser = () => async (dispatch: any) => {
  const email = localStorage.getItem('email');
  if (email) {
    const {status, ...user} = await UserService.getProfile(email);
    if (status == 200) {
      dispatch({
        type: UserTypes.SET_USER,
        payload: user,
      });
    } else {
      dispatch({
        type: SnackbarTypes.SHOW_ERROR,
        payload: `User not found.`,
      });
    }

    return user;
  }
};

export const updateUser = (body: any) => async (dispatch: any) => {
  const email = localStorage.getItem('email');
  if (email) {
    try {
      await UserService.updateUser(body, email);
    } catch (e) {
      dispatch({
        type: SnackbarTypes.SHOW_ERROR,
        payload: `Error! Try again.`,
      });
    }
  }
}


