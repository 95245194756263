import styles from '../src/styles';
import { Avatar, Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { customColors } from '../../../theme/palette';
import CEO from '../src/CEO.png';
import { breakPoints } from '../src/breakpoints';

const breaks = breakPoints(1680, 785, 785, 25, 800);
const breaks2 = breakPoints(800, 785, 785, 50, 300);

export default () => (
  <Box sx={{...styles.contentBox, alignItems: "center", flexWrap: "wrap-reverse", ['@media (max-width:800px)']: {
      flexDirection: "column-reverse"
    }}}>
    <Box sx={{...styles.innerBox, maxWidth: "30%", marginRight: "100px", ['@media (max-width:800px)']: {
        maxWidth: "50%",
        alignItems: "center",
        textAlign: "center",
        minHeight: "10%",
        marginTop: "40px",
        marginRight: "0px"
      } }}>
      <Typography variant="h2" sx={{...styles.defaultMargin, fontWeight: "bold", ...styles.resizeH2}}>
        WHO WE ARE
      </Typography>
      <Typography variant="h3" sx={{...styles.defaultMargin, ...styles.resizeH3}}>
        We get it. Life is busy. That doesn't
        mean you shouldn't be able to spend
        your time doing the things you love,
        and look flyy doing it.
      </Typography>
      <Typography variant="h3" sx={{...styles.defaultMargin, ...styles.resizeH3}}>
        The world has changed where you
        can get what you want anytime you
        want for a number of things, and
        fashion shouldn't be any different.
      </Typography>
      <Box sx={{...styles.ceoBox}}>
        <Box>
          <img src={CEO} style={{maxHeight: "95px", maxWidth: "95px"}}/>
        </Box>
        <Box sx={{marginLeft: "30px"}}>
          <Typography variant="h3" sx={{fontWeight: 'bold', color: customColors.darkPurple, ...styles.resizeH3}}>
            JORESA BLOUNT
          </Typography>
          <Typography variant="subtitle1" sx={{color: customColors.gray}}>
            FOUNDER-CEO, GOFLYY
          </Typography>
        </Box>
      </Box>
    </Box>
    <CardMedia sx={{
      height: "785px",
      width: "785px",
      borderRadius: "12px",
      ...breaks,
      ...breaks2
    }} image={require('../src/R2.png')}/>
  </Box>
)