import { SnackbarTypes } from './types';

export const showSuccessMessage = (message = `Success!`) => (dispatch: any) =>
  dispatch({
    type: SnackbarTypes.SHOW_SUCCESS,
    payload: message,
  });

export const showErrorMessage =
  (message = `Error! Try again.`) =>
    (dispatch: any) =>
      dispatch({
        type: SnackbarTypes.SHOW_ERROR,
        payload: message,
      });

export const clearMessage = () => (dispatch: any) =>
  dispatch({
    type: SnackbarTypes.CLEAR,
  });
