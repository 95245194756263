import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './rootReducer';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { sessionService } from 'redux-react-session';

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

sessionService
  .initSessionService(store, {
    refreshOnCheckAuth: true,
    driver: 'COOKIES',
    expires: 86400000,
    validateSession: (session) => {
      console.log(`session:`)
      console.log(session)
      return !!session;
    }
  })
  .then(() =>
    console.log(
      'Redux React Session is ready and a session was refreshed from your storage'
    )
  )
  .catch(() =>
    console.log(
      'Redux React Session is ready and there is no session in your storage'
    )
  );
