
export const InitialNotifiesState = {

};

export const InitialUserState = {
  first_name: ``,
  last_name: ``,
  phone: ``,
  country_code: ``,
  email: localStorage.getItem('email'),
  dob: ``,
  gender: ``,
  notes: ``,
  profile_pic: ``,
  country: ``,
  state: ``,
  city: ``,
  zip: ``,
  address_details: ``,
  password: ``,
  address1: ``,
  address2: ``,
  banks: [],
};
