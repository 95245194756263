import { customColors } from '../../../theme/palette';

export default {
  button: {
    borderRadius: "16px",
    boxShadow: "0px 0px",
    transition: "",
    fontSize: 16,
    fontWeight: 500,
    padding: "10px"
  },
  blackButton: {
    backgroundColor: customColors.black,
    color: customColors.white,
    "&:hover": {backgroundColor: customColors.black},
  },
  whiteButton: {
    backgroundColor: customColors.white,
    color: customColors.black,
    "&:hover": {backgroundColor: customColors.lightGray},
    border: `1px solid ${customColors.gray}`
  },
  blueButton: {
    backgroundColor: customColors.blue,
    color: customColors.white,
    "&:hover": {backgroundColor: customColors.blue},
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    paddingTop: "5%",
    minHeight: "800px"
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    marginBottom: "5%",
    width: "60%",
    ['@media (max-width:1200px)']: {
      width: "92%",
    },
    ['@media (max-width:900px)']: {
      width: "94%",
    },
  },
  input: {
    minWidth: "260px",
    [`& fieldset`]: {
      fontSize: "16px",
      borderRadius: "16px"
    },
    ['@media (max-width:640px)']: {
      width: "100%",
      marginLeft: "0px",
      marginTop: "20px"
    },
    ['@media (max-width:400px)']: {
      width: "90%",
    },
  },
  cardInput: {
    width: "48%"
  },
  defaultMargin: {
    marginBottom: "10px"
  },
  twoFieldsBox: {
    display: "flex",
    flexDirection: "row",
    ['@media (max-width:640px)']: {
      flexDirection: "column",
    },
  },
  buttons: {
    borderRadius: "16px",
    minWidth: "260px",
    maxWidth: "100%",
    fontSize: 16,
    ['@media (max-width:640px)']: {
      width: "100%",
      marginLeft: "0px",
      marginTop: "20px"
    },
    ['@media (max-width:400px)']: {
      width: "90%",
    },
  },
  checkbox: {
    color: customColors.primary,

  },
  tab: {
    backgroundColor: customColors.lightGray,
    color: customColors.black,
    borderRadius: "16px",
    '&.Mui-selected': {
      color: customColors.black,
      backgroundColor: customColors.primary,
    },
    fontSize: 16
  },
  dropDownInput: {
    minWidth: "200px",
    [`& fieldset`]: {
      borderRadius: "16px",
      fontSize: 16
    },
  },
  orderBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderRadius: "32px",
    padding: "10px",
    height: "100px",
    justifyContent: "space-between",
  },
  intoBox: {
    display: "flex",
    flexDirection: "column",
  },
  modalBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    minHeight: "600px",
    alignItems: "center",
    borderWidth: "0px"
  },
  modal: {
    minHeight: "500px",
    backgroundColor: customColors.white,
    borderRadius: "32px",
    display: "flex",
    flexDirection: "column",
    width: "60%",
    alignItems: "center",
    ['@media (max-width:1000px)']: {
      width: "90%",
    },
    ['@media (max-width:800px)']: {
      width: "96%",
    },
    ['@media (max-width:640px)']: {
      minHeight: "650px"
    },
  },
  insideModalBox: {
    display: "flex",
    width: "90%",
    height: "96%",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  orderDetailsRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "25px",
    alignItems: "center"
  },
  orderDetailName: {
    color: customColors.darkGray,
    width: "100px"
  },
  productDetail: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "140px",
    alignItems: "center"
  },
  balance: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    width: "50%",
    borderRadius: "32px",
    backgroundColor: customColors.lightGray,
    minHeight: "150px",
    minWidth: "280px"
  },
  supportLine: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "48px",
    alignContent: "center",
    alignItems: "center",
    paddingLeft: "10px",
    borderRadius: "16px",
  },
  ticketMessage: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "32px",
    width: "100%",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    border: `2px solid ${customColors.lightGray}`,
    minHeight: "50px",
    marginBottom: "20px"
  },
  userMessage: {
    backgroundColor: customColors.lightGray
  },
  carPlate: {
    display: "flex",
    width: "100%",
    minHeight: "190px",
    backgroundColor: "#2D3033",
    borderRadius: "32px",
    flexDirection: "column",
    justifyContent: "space-around",
    marginBottom: "30px",
  },
  carPlateLine: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
  }
}