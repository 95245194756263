import { Box, Button, Typography } from '@mui/material';
import styles from '../../Dashboard/src/styles';
import { useEffect, useState } from 'react';
import { UserService } from '../../../api/services/user-service';
import { parseQuestionnaire } from '../../../utils/parse-questionary';
import { customColors } from '../../../theme/palette';
import Loader from '../../LandingPage/components/Loader';
import { Style } from '@mui/icons-material';
import StyledButton from '../../UI/StyledButton';
import { useActions } from '../../../hooks/useHooks';

export default ({handleQnaSubmit, qna, setQna}: any) => {
  const [loading, setLoading] = useState(true);
  const {showErrorMessage, showSuccessMessage, } = useActions();

  useEffect(() => {
    try {
      const email = localStorage.getItem('email');
      if (email) {
        UserService.getQuestionnaire(email)
          // @ts-ignore
          .then((res) => {
            const parsed = parseQuestionnaire(res);
            setQna(parsed);
            setLoading(false);
          })
      }
    } catch (e) {
      showErrorMessage();
    }
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let send = true;
    setLoading(true);
    Object.entries(qna).map(([key, value]: any) => {
      if (!value.answer || !value.answer.length) {
        showErrorMessage(`Answer all questions`);
        setLoading(false);
        send = false;
      }
    });
    if (send) {
      handleQnaSubmit();
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      {!loading ?
      <Box sx={{display: "flex", flexDirection: "column", width: "100%", justifyContent: "center" }}>
      {
        Object.entries(qna).map(([key, value]: any) => {

          return (
            <Box sx={{display: "flex", flexDirection: "column", width: "100%", borderBottom: `2px dashed ${customColors.gray}`, marginBottom: "15px", paddingBottom: "15px"}}>
              <Typography variant="subtitle1">
                {
                  value.question
                }
              </Typography>
                <Box sx={{display: 'flex', width: '100%', flexDirection: "column", marginTop: "7px"}}>
                {
                  Object.entries(value.answerOptions).map(([answerKey, answerOption]: any) => {
                    return answerOption ? (
                      <Button
                        onClick={() => setQna((prevState: any) => (
                          {
                            ...prevState,
                            [key]: {
                              ...prevState[key],
                              answer: answerKey
                            }
                          }
                        ))}
                        variant="outlined"
                        disabled={value.answer == answerKey}
                        sx={{
                          color: customColors.black,
                          borderColor: customColors.darkGray,
                          borderRadius: "50px",
                          fontSize: 12,
                          marginBottom: "10px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          "&:disabled": {
                            color: customColors.black,
                            borderColor: customColors.primary,
                            backgroundColor: customColors.primary,
                          }
                        }}
                      >
                        {answerOption}
                      </Button>
                    ) : null
                  })
                }
                </Box>
            </Box>
          )
        })
      }
      <StyledButton text="Next step" sx={{
        minWidth: "240px",

      }}
      type="submit" />
    </Box>
    : <Loader />}
    </form>
  )
}