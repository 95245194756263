import SupportTypes from './types';
import { SupportService } from '../../api/services/support-service';

export const getTickets = () => async (dispatch: any) => {
  const data = await SupportService.getTickets();
  if (data.length) {
    dispatch({
      type: SupportTypes.SET_TICKETS,
      payload: data
    })
  }
};

export const setCurrentTicket = (ticket: any) => (dispatch: any) => {
  dispatch({
    type: SupportTypes.SET_CURRENT_TICKET,
    payload: ticket
  });
};
