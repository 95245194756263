import { InitialSupportState } from './constants';
import SupportTypes from './types';

const initialState = InitialSupportState;

const changeField = (draft: any, fieldName: any, value: any) => ({
  ...draft,
  [fieldName]: value,
});

export default (state = initialState, action: any = "UPDATE_NOTIFICATIONS_DRAFT") => {
  const newState = { ...state };

  switch (action.type) {
    case SupportTypes.SET_TICKETS:
      return {
        ...state,
        tickets: action.payload
      };

    case SupportTypes.SET_CURRENT_TICKET:
      return {
        ...state,
        currentTicket: action.payload
      };

    default:
      return newState;
  }
};