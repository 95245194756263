import { Box, Button, CardMedia, IconButton, InputAdornment, Link, Tab, TextField, Typography } from '@mui/material';
import styles from "./src/styles";
import React, { useEffect, useMemo, useState } from 'react';
import StyledButton from '../UI/StyledButton';
import { customColors } from '../../theme/palette';
import GFIcon from '../UI/GFIcon';
import { testEmail } from './constants';
import { useNavigate } from 'react-router-dom';
import { useAuth, useAuthRedirect } from '../../utils/use-auth';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UserInfo from '../Profile/components/UserInfo';
import Payment from '../Profile/components/Payment';
import NotificationsSettings from '../Profile/components/NotificationsSettings';
import Licence from "./components/Licence";
import Vehicle from './components/Vehicle';
import { UserService } from '../../api/services/user-service';
import Questionnaire from './components/Questionnaire';
import { useActions, useUser } from '../../hooks/useHooks';
import PhoneModal from '../Profile/components/PhoneModal';


const InitNameFormValue = {
  first_name: '',
  last_name: '',
  phone: '',
  country_code: ``,
  dob: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: ''
};

const InitInsurancePolice = {
  insurer_name: '',
  number: '',
  iss: '',
  exp: '',
};

const Titles = {
  DEFAULT: `Checking and verification profile information may take several days. After confirmation, you will receive a notification.`,
  DECLINED: `The data has not been validated.`
}

export default () => {
  useAuthRedirect();
  const [nameForm, changeNameForm]: any = useState(InitNameFormValue);
  const [policyForm, changePolicyForm] = useState(InitInsurancePolice);
  const [tabValue, changeTabValue] = useState('1');
  const [qna, setQna]: any = useState({});
  const [isDeclined, setDeclined] = useState(false);
  const [isVerifiedTel, setIsVerifiedTel] = useState(false);

  const navigate = useNavigate();
  const { userDraft } = useUser();
  const { getCurrentUser, updateUser, showErrorMessage, showSuccessMessage,  } = useActions();

  const isDone = useMemo(() => {
    if (userDraft.documents && userDraft.documents.length && userDraft.first_name
      && userDraft.last_name && userDraft.dob) {
      if (userDraft.inspolicies && userDraft.inspolicies.length && userDraft.vehicles && userDraft.vehicles.length) {
        return '3';
      } else {
        return '2';
      }
    } else {
      return '1';
    }
  }, [userDraft]);


  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      UserService.getBackgroundCheckStatus(email)
        // @ts-ignore
        .then((result) => {
          switch (result.message) {
            case null: {
              getCurrentUser()
                // @ts-ignore
                .then((res: any) => {
                  if (res.phone.length) {
                    setIsVerifiedTel(true);
                  }
                  changeTabValue(isDone);
                  changeNameForm((prevState: any) => ({...prevState, ...res}));
                }).catch((e: any) => {
                  showErrorMessage();
                });
              break;
            }
            case 'Approved':
            case 'Approve': {
              showSuccessMessage(`You've been approved`);
              navigate('/profile');
              break;
            }
            case 'Decline':
            case 'Declined': {
              setDeclined(true);
              changeTabValue('4');
              break;
            }
            default: {
              changeTabValue('4');
              break;
            }
          }
        })
    }
  }, []);

  const handleNameFormSubmit = async (event: any) => {
    const {phone, ...body} = nameForm;
    if (phone !== userDraft.phone && !isVerifiedTel) {
      body.phone = phone;
    }
    updateUser(body)
      // @ts-ignore
      .then(() => {
        if (userDraft.inspolicies && userDraft.inspolicies.length && userDraft.vehicles && userDraft.vehicles.length) {
          changeTabValue('3');
        } else {
          changeTabValue('2');
        }
      })
  };

  const handlePolicyFormSubmit = async (event: any) => {
    event.preventDefault();
    const email = localStorage.getItem('email');
    if (email) {
      const resp = await UserService.addPolicy(email, policyForm);
      if (resp.status === "200" && resp.data === "policy_successfully_added") {
        changeTabValue('3');
      } else {
        showErrorMessage();
      }
    }
  };

  const handleQnaSubmit = async () => {
    const email = localStorage.getItem('email');
    if (email) {
      const {status, data} = await UserService.setQnaAnswers(email, qna);
      if (status === "200" && data === "ok") {
        const resp = await UserService.setBackgroundCheckStatusPending(email);
        if (resp.status === "200") {
          changeTabValue('4');
        } else {
          showErrorMessage();
        }
      }
    }
  }

  return (
    <Box sx={{...styles.generalBox}}>
      <Box sx={{...styles.contentBox}}>
        <Button sx={{marginTop: "40px"}} onClick={() => navigate('/')}>
          <GFIcon />
        </Button>
        <Box sx={{...styles.loginBox, flexDirection: "column", justifyContent: "flex-start", alignItems: "center"}}>
          <Typography variant="h2" sx={{marginBottom: "20px", }}>
            Person Identifications
          </Typography>
          <TabContext value={tabValue}>
            <Box sx={{display: "flex",
              "& .MuiTabs-indicator": {
                display: "none"
              }}}>
              <TabList sx={{borderRadius: "16px", backgroundColor: customColors.lightGray,

              }}>
                <Tab label="Driver's license" value="1" sx={{...styles.tab}}/>
                <Tab label="Vehicle" value="2" sx={{...styles.tab}}/>
                <Tab label="Quiz" value="3" sx={{...styles.tab}}/>
                <Tab label="Check in" value="4" sx={{...styles.tab}}/>
              </TabList>
            </Box>
            <TabPanel value="1">
              <Licence nameForm={nameForm} changeNameForm={changeNameForm} handleNameFormSubmit={handleNameFormSubmit} isVerifiedTel={isVerifiedTel}/>
            </TabPanel>
            <TabPanel value="2">
              <Vehicle handlePolicyFormSubmit={handlePolicyFormSubmit} policyForm={policyForm} changePolicyForm={changePolicyForm} />
            </TabPanel>
            <TabPanel value="3">
              <Questionnaire handleQnaSubmit={handleQnaSubmit} qna={qna} setQna={setQna}/>
            </TabPanel>
            <TabPanel value="4">
              <Typography variant="subtitle1">
                {
                  !isDeclined ? Titles.DEFAULT : Titles.DECLINED
                }

              </Typography>
              {
                isDeclined ?
                  <StyledButton sx={{padding: "15px", }}
                  onClick={() => {
                    localStorage.removeItem('email');
                    localStorage.removeItem('token');
                    navigate('/login');
                  }}
                  text=" Back to sign up"
                  />
                  : null
              }
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  )
}