import LandingPage from '../components/LandingPage/LandingPage';
import AboutUs from '../components/LandingPage/AboutUs';
import SignIn from '../components/SignInUp/SignIn';
import SignUp from '../components/SignInUp/SignUp';
import ResetPassword from '../components/SignInUp/ResetPassword';
import { useRoutes } from 'react-router-dom';
import Profile from '../components/Profile/Profile';
import ConfirmEmail from "../components/SignInUp/ConfirmEmail";
import Support from '../components/Support/Support';
import NewTicket from '../components/Support/NewTicket';
import Ticket from '../components/Support/Ticket';
import PersonIndentification from '../components/SignInUp/PersonIndentification';
import BillingHistory from '../components/BillingHistory/BillingHistory';

export const publicRoutePaths = {
  landing: '*',
  aboutUs: '/about',
  signIn: '/login',
  signUp: '/registration',
  resetPassword: '/reset-password',
  confirmEmail: '/confirmEmail/*',
  identification: '/identification',

  //TODO: change place for private routes
  profile: '/profile',
  dashboard: '/dashboard',
  newReturn: '/new-return',
  support: '/support',
  newTicket: '/new-ticket',
  ticket: '/support/*',
  billingHistory: 'billing-history',
};

export const PublicRoutes = () => useRoutes([
  {
    path: publicRoutePaths.landing,
    element: <LandingPage />,
  },
  {
    path: publicRoutePaths.aboutUs,
    element: <AboutUs />,
  },
  {
    path: publicRoutePaths.signIn,
    element: <SignIn />
  },
  {
    path: publicRoutePaths.signUp,
    element: <SignUp />
  },
  {
    path: publicRoutePaths.resetPassword,
    element: <ResetPassword />
  },
  {
    path: publicRoutePaths.confirmEmail,
    element: <ConfirmEmail />
  },
  {
    path: publicRoutePaths.profile,
    element: <Profile />
  },
  {
    path: publicRoutePaths.support,
    element: <Support />
  },
  {
    path: publicRoutePaths.newTicket,
    element: <NewTicket />
  },
  {
    path: publicRoutePaths.ticket,
    element: <Ticket />
  },
  {
    path: publicRoutePaths.identification,
    element: <PersonIndentification />
  },
  {
    path: publicRoutePaths.billingHistory,
    element: <BillingHistory />
  },
]);