const KEYS = {
  QUESTION: 'question',
  ANSWER: 'answer'
}

export const parseQuestionnaire = (body: any) => {
  const obj = body[0];
  const bodyArray = Object.entries(obj);
  const result: any = {};
  for (const [key, value] of bodyArray) {
    if (key.includes(KEYS.QUESTION)) {
      const [, number] = key.split(KEYS.QUESTION);
      result[key] = {
        answerOptions: {
          [`answer${number}_option1`]: obj[`answer${number}_option1`],
          [`answer${number}_option2`]: obj[`answer${number}_option2`],
          [`answer${number}_option3`]: obj[`answer${number}_option3`],
          [`answer${number}_option4`]: obj[`answer${number}_option4`],
        },
        question: value,
        answer: '',
      };
    }
  }
  return result;
}