import api from '../api';

export class SupportService {
  static async createTicket (body: any) {
    const bodyArray: any = Object.entries(body);
    const email = localStorage.getItem('email');
    const URL = `/createTicket`;
    const formData = new FormData();
    formData.append('email', email ? email : ``);
    for (const [key, value] of bodyArray) {
      formData.append(key, value);
    }
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async addTicketMessage (body: any) {
    const bodyArray: any = Object.entries(body);
    const email = localStorage.getItem('email');
    const URL = `/addTicketMessage`;
    const formData = new FormData();
    formData.append('email', email ? email : ``);
    formData.append('author_email', email ? email : ``);
    for (const [key, value] of bodyArray) {
      formData.append(key, value);
    }
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getTickets () {
    const email = localStorage.getItem('email');
    const URL = `/getTickets`;
    const formData = new FormData();
    formData.append('email', email ? email : ``);
    formData.append('max', "20");
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getTicketById (id: string) {
    const email = localStorage.getItem('email');
    const URL = `/getTicket`;
    const formData = new FormData();
    formData.append('email', email ? email : ``);
    formData.append('ticket_id', id);
    const {data} = await api.post(URL, formData);
    return data;
  }
}
