import * as UserActionCreators from './user/actions';
import * as NotificationsActionCreators from './notifications/actions';
import * as SupportActionCreators from './support/actions';
import * as SnackbarActionCreators from './snackbar/actions';

export default {
  ...UserActionCreators,
  ...NotificationsActionCreators,
  ...SupportActionCreators,
  ...SnackbarActionCreators,
};