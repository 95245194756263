import styles from '../src/styles';
import { Box, CardMedia, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import React from 'react';
import { customColors } from '../../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import { useNavigate } from 'react-router-dom';

const breaks = breakPoints(1680, 785, 785, 30, 800);
const breaks2 = breakPoints(800, 750, 750, 40);

export default () => {
  const navigate = useNavigate();
  return (
    <Box sx={{...styles.contentBox, width: "100%", justifyContent: "center", minHeight: "760px"}}>
      <CardMedia sx={{
        height: "785px",
        width: "785px",
        borderRadius: "12px",
        ...breaks,
        ...breaks2
      }} image={require('../src/R4.png')}/>
      <Box sx={{...styles.innerBox, ...styles.sidedContent, minHeight: "20%", width: "35%", marginLeft: "30px",
        ...breaks,
        ['@media (max-width:800px)']: {
          width: "100%",
          maxWidth: "100%",
          marginLeft: "0px",
          minHeight: "10%",
          marginTop: "40px",
        },}}>
        <Box sx={{...styles.joinUsBox,}}>
          <Typography variant="h2" sx={{...styles.resizeH2}}>
            BECOME
          </Typography>
          <Typography variant="h2" sx={{...styles.defaultMargin, ...styles.resizeH2}}>
            A DRIVER
          </Typography>
          <Typography variant="h4" sx={{...styles.defaultMargin}}>
            Drive for GoFlyy and enjoy flexible hours and great hourly pay
          </Typography>
          <StyledButton text="Get Started" size="big" sx={{maxWidth: "200px", "&:hover": {backgroundColor: customColors.secondary}}}
                        onClick={() => navigate('/registration')}
          />

        </Box>
      </Box>

    </Box>
  )
}