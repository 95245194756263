import { useCallback, useEffect, useState } from 'react';
import { InitialUserState } from '../../../redux/user/constants';
import { Box, Button, CardMedia, TextField, Typography } from '@mui/material';
import landStyles from '../../LandingPage/src/styles';
import { customColors } from '../../../theme/palette';
import styles from '../../Dashboard/src/styles';
import StyledButton from '../../UI/StyledButton';
import { useSelector } from 'react-redux';
import Loader from '../../LandingPage/components/Loader';
import { useActions, useUser } from '../../../hooks/useHooks';
import { compareDraft } from '../../../utils/compare-draft';
import { FileService } from '../../../api/services/file-service';
import { useNavigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import MuiPhoneNumber from 'material-ui-phone-number';
import { TimePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { UserService } from '../../../api/services/user-service';
import { AddressInfoKeys, ContactInfoKeys } from '../src/constants';
import PhoneModal from './PhoneModal';

const InitialDayState: any = {
  start_time: new Date(),
  end_time: new Date()
}
const getSendTimeFormat = (date: any) => `${date.getHours()}:${date.getMinutes()}:00`

export default () => {
  const [user, setUser]: any = useState(InitialUserState);
  const [loading, setLoading] = useState(true);
  const [monday, setMonday] = useState(InitialDayState);
  const [tuesday, setTuesday] = useState(InitialDayState);
  const [wednesday, setWednesday] = useState(InitialDayState);
  const [thursday, setThursday] = useState(InitialDayState);
  const [friday, setFriday] = useState(InitialDayState);
  const [saturday, setSaturday] = useState(InitialDayState);
  const [sunday, setSunday] = useState(InitialDayState);
  const [car, changeCar]: any = useState({});
  const [insurance, changeInsurance]: any = useState({});
  const [modalTrigger, setModalTrigger] = useState(false);
  const userDraft = useUser();
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile]: any = useState({
    image: userDraft.userDraft.profile_pic,
    file: null
  });
  const { getCurrentUser, updateUserDraft, updateUser, clearUserDraft, showErrorMessage, showSuccessMessage } = useActions();

  const parseSchedule = useCallback(({start_time, end_time, weekday}: any) => {
    const start = new Date(start_time * 1000);
    const end = new Date(end_time * 1000);
      if (weekday.includes('Mon')) {
        setMonday({
          start_time: start,
          end_time: end
        });
      }
      if (weekday.includes('Tue')) {
        setTuesday({
          start_time: start,
          end_time: end
        });
      }
      if (weekday.includes('Wed')) {
        setWednesday({
          start_time: start,
          end_time: end
        });
      }
      if (weekday.includes('Thu')) {
        setThursday({
          start_time: start,
          end_time: end
        });
      }
      if (weekday.includes('Fri')) {
        setFriday({
          start_time: start,
          end_time: end
        });
      }
      if (weekday.includes('Sat')) {
        setSaturday({
          start_time: start,
          end_time: end
        });
      }
      if (weekday.includes('Sun')) {
        setSunday({
          start_time: start,
          end_time: end
        });
      }
  }, []);

  useEffect(() => {
    setSelectedFile((prevState: any) => ({ ...prevState, image: userDraft.userDraft.profile_pic }) );
  }, [userDraft.userDraft.profile_pic]);

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      getCurrentUser()
        // @ts-ignore
        .then((res: any) => {
          setUser((prevState: any) => ({...prevState, ...res, email: localStorage.getItem('email')}));
          changeCar(res.vehicles[0]);
          changeInsurance(res.inspolicies[0])
          UserService.getSchedule(email)
            .then((result: any) => {
              if (result.status === "200") {
                result.message.map((obj: any) => parseSchedule(obj));
                setLoading(false);
              }
            })
        }).catch((e: any) => {
        showErrorMessage();
      });
    }
  }, []);


  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      const newFields = compareDraft(user, userDraft.userDraft);
      const newFieldsKeys = Object.keys(newFields);
      const isContact = ContactInfoKeys.some((r: any) => newFieldsKeys.includes(r));
      const isAddress = AddressInfoKeys.some((r: any) => newFieldsKeys.includes(r));
      if (isContact) {
        newFields.first_name = userDraft.userDraft.first_name;
        newFields.last_name = userDraft.userDraft.last_name;
        newFields.sex = userDraft.userDraft.sex;
        if (newFields.dob) {
          newFields.dob = `${newFields.dob.getFullYear()}-${newFields.dob.getMonth() + 1}-${newFields.dob.getDate()}`;
        } else {
          newFields.dob = userDraft.userDraft.dob;
        }
      }
      if (isAddress) {
        newFields.address1 = userDraft.userDraft.address1;
        newFields.city = userDraft.userDraft.city;
        newFields.state = userDraft.userDraft.state;
        newFields.zip = userDraft.userDraft.zip;
        newFields.country = userDraft.userDraft.country;
      }
      if (
        Object.keys(newFields).length
      ) {
        updateUser(newFields);
      }
      if (selectedFile.image && selectedFile.file) {
        try {
          const resp = await FileService.uploadProfilePicture(selectedFile.file);
          if (resp.status !== "200") {
            showErrorMessage(`Profile picture failed.`);
          }
        } catch (e) {
          showErrorMessage(`Profile picture failed.`);
        }
        updateUser({ ...newFields });
      }
      if (newFields.phone && newFields.phone.length) {
        setModalTrigger(true);
      }
      setUser((prevState: any) => ({ ...prevState, ...newFields }));
      //schedule
      const email = localStorage.getItem(`email`);
      if (email) {
        await UserService.addSchedule(email, {
          weekday: "Mon",
          start_time: getSendTimeFormat(monday.start_time),
          end_time: getSendTimeFormat(monday.end_time)
        });
        await UserService.addSchedule(email, {
          weekday: "Tue",
          start_time: getSendTimeFormat(tuesday.start_time),
          end_time: getSendTimeFormat(tuesday.end_time)
        });
        await UserService.addSchedule(email, {
          weekday: "Wed",
          start_time: getSendTimeFormat(wednesday.start_time),
          end_time: getSendTimeFormat(wednesday.end_time)
        });
        await UserService.addSchedule(email, {
          weekday: "Thu",
          start_time: getSendTimeFormat(thursday.start_time),
          end_time: getSendTimeFormat(thursday.end_time)
        });
        await UserService.addSchedule(email, {
          weekday: "Fri",
          start_time: getSendTimeFormat(friday.start_time),
          end_time: getSendTimeFormat(friday.end_time)
        });
        await UserService.addSchedule(email, {
          weekday: "Sat",
          start_time: getSendTimeFormat(saturday.start_time),
          end_time: getSendTimeFormat(saturday.end_time)
        });
        await UserService.addSchedule(email, {
          weekday: "Sun",
          start_time: getSendTimeFormat(sunday.start_time),
          end_time: getSendTimeFormat(sunday.end_time)
        });
        showSuccessMessage();
      }
    } catch (e) {
      showErrorMessage();
    } finally {
      setLoading(false)
    }
  };

  const handleFileSelect = ({ target }: any) => {
    const [file] = target.files;
    const reader = new FileReader();

    reader.onload = () => {
      setSelectedFile({
        image: reader.result,
        file: file,
      });
    };

    reader.readAsDataURL(file);
    target.value = '';
  };


  return (
    <form onSubmit={handleSubmit}>
      <PhoneModal modalTrigger={modalTrigger} setModalTrigger={setModalTrigger} phone={userDraft.userDraft.phone}  country_code={userDraft.userDraft.country_code}/>
      {loading ? <Loader /> :
    (<Box sx={{display: "flex", flexDirection: "row",
      ['@media (max-width:900px)']: {
        flexDirection: "column",
      },
    }}>
      <Box sx={{marginRight: "30px"}}>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin}}>
        <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Contact information</Typography>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField label="First Name" variant="outlined" sx={{...styles.input}} value={userDraft.userDraft.first_name}
                    onChange={e => updateUserDraft('first_name', e.target.value )}
          />
          <TextField label="Last Name" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.last_name}
                     onChange={e => updateUserDraft('last_name', e.target.value )}
          />
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <MuiPhoneNumber defaultCountry={'us'}
                          sx={{...styles.input, justifyContent: "center"}}
                          excludeCountries={['ru', ]}
                          onlyCountries={['us']}
                          disableAreaCodes={true}
                          value={userDraft.userDraft.country_code ? (userDraft.userDraft.country_code + userDraft.userDraft.phone) : userDraft.userDraft.phone}
                          variant="outlined"
                          onChange={e => {
                            if (typeof e === 'string') {
                              const numbers = e.split(' (');
                              updateUserDraft('country_code', numbers[0]);
                              const regionCode = numbers[1] ? numbers[1].split(') ') : ['', ''];
                              const phoneNumbers = regionCode[1] ? regionCode[1].split('-') : ['', ''];
                              updateUserDraft('phone', regionCode[0] + phoneNumbers[0] + phoneNumbers[1]);
                            }
                          }}/>
          <TextField label="E-mail address" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={localStorage.getItem('email')}
                     onChange={e => updateUserDraft('email', e.target.value )}
          />
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Date of birth"
            value={userDraft.userDraft.dob}
            maxDate={Date.now()}
            onChange={(newValue) => {
              updateUserDraft('dob', newValue);
            }}
            renderInput={(params) => <TextField sx={{...styles.input}} {...params} helperText={null} />}
          />
          </LocalizationProvider>
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="Address" variant="outlined" type="required" sx={{...styles.input}} value={userDraft.userDraft.address1}
                     onChange={e => updateUserDraft('address1', e.target.value )}/>
          <TextField label="Second Address" variant="outlined" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.address2}
                     onChange={e => updateUserDraft('address2', e.target.value )}/>
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="City" variant="outlined" type="required" sx={{...styles.input}} value={userDraft.userDraft.city}
                     onChange={e => updateUserDraft('city', e.target.value )}/>
          <TextField required label="State" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.state}
                     onChange={e => updateUserDraft('state', e.target.value)}/>
        </Box>
        <Box sx={{ ...styles.defaultMargin, ...styles.twoFieldsBox}}>
          <TextField required label="ZIP" type="number" variant="outlined" sx={{...styles.input}} value={userDraft.userDraft.zip}
                     onChange={e => updateUserDraft('zip', e.target.value )} inputProps={
            { readOnly: !!user.zip, }
          }/>
          <TextField required label="Country" variant="outlined" type="required" sx={{...styles.input, marginLeft: "20px"}} value={userDraft.userDraft.country}
                     onChange={e => updateUserDraft('country', e.target.value )}/>
        </Box>
        <TextField fullWidth label="Details" variant="outlined" sx={{...styles.input}} value={userDraft.userDraft.address_details}
                   onChange={e => updateUserDraft('address_details', e.target.value)}/>
      </Box>
      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin}}>
      <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Availability ({Intl.DateTimeFormat().resolvedOptions().timeZone})</Typography>
      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Mon
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={monday.start_time}
            onChange={(newValue) => {
              setMonday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={monday.end_time}
            minTime={monday.start_time}
            onChange={(newValue) => {
              setMonday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Tue
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={tuesday.start_time}
            onChange={(newValue) => {
              setTuesday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={tuesday.end_time}
            minTime={tuesday.start_time}
            onChange={(newValue) => {
              setTuesday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Wed
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={wednesday.start_time}
            onChange={(newValue) => {
              setWednesday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={wednesday.end_time}
            minTime={wednesday.start_time}
            onChange={(newValue) => {
              setWednesday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Thu
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={thursday.start_time}
            onChange={(newValue) => {
              setThursday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={thursday.end_time}
            minTime={thursday.start_time}
            onChange={(newValue) => {
              setThursday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Fri
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={friday.start_time}
            onChange={(newValue) => {
              setFriday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={friday.end_time}
            minTime={friday.start_time}
            onChange={(newValue) => {
              setFriday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Sat
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={saturday.start_time}
            onChange={(newValue) => {
              setSaturday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={saturday.end_time}
            minTime={saturday.start_time}
            onChange={(newValue) => {
              setSaturday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>

      <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", marginBottom: "15px"}}>
        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "36px"}}>
          Sun
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="Start"
            value={sunday.start_time}
            onChange={(newValue) => {
              setSunday((prevState: any) => ({
                ...prevState,
                start_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params}
            sx={{marginRight: "10px"}}
            />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            label="End"
            value={sunday.end_time}
            minTime={sunday.start_time}
            onChange={(newValue) => {
              setSunday((prevState: any) => ({
                ...prevState,
                end_time: newValue
              }));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      </Box>

      <Box sx={{display: "flex", flexDirection: "column", maxWidth: "540px", ...landStyles.defaultMargin}}>
        <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Vehicle and Insurance policy</Typography>
        <Box sx={{...styles.carPlate, paddingLeft: "20px", width: "520px"}}>
          <Typography sx={{
            width: "100%",
            fontSize: 24,
            color: "white",
          }}>{car.make} {car.model}</Typography>
          <Box>
            <Box sx={{...styles.carPlateLine}}>
              <Typography sx={{width: "40%", color: customColors.purple}}>
                VIN
              </Typography>
              <Typography sx={{width: "15%", color: customColors.purple}}>
                Year
              </Typography>
              <Typography sx={{width: "20%", color: customColors.purple}}>
                Color
              </Typography>
              <Typography sx={{width: "22%", color: customColors.purple}}>
                Number plate
              </Typography>
            </Box>
            <Box sx={{...styles.carPlateLine}}>
              <Typography sx={{width: "40%", color: customColors.white}}>
                {car.vin}
              </Typography>
              <Typography sx={{width: "15%", color: customColors.white}}>
                {car.year}
              </Typography>
              <Typography sx={{width: "20%", color: customColors.white}}>
                color
              </Typography>
              <Typography sx={{width: "22%", color: customColors.white}}>
                {car.plate}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{...styles.carPlate, backgroundColor: "white", border: `1px solid ${customColors.gray}`, paddingLeft: "20px", paddingRight: "20px", width: "500px"}}>
          <Box>
            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Typography sx={{
                fontSize: 16,
                color: customColors.gray,
              }}>Insurance policy</Typography>
              <Typography sx={{
                fontSize: 16,
                color: customColors.primary,
              }}>{insurance.number}</Typography>
            </Box>
            <Typography sx={{
              width: "100%",
              fontSize: 24,
              color: "black",
              fontWeight: 700,
            }}>{insurance.insurer_name}</Typography>
          </Box>
          <Box>
            <Box sx={{...styles.carPlateLine, justifyContent: "space-between"}}>
              <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-around", width: "40%",
                backgroundColor: customColors.lightGray, borderRadius: "16px", padding: "4px 0px"}}>
                <Typography sx={{
                  fontSize: 16,
                  color: "black",
                  fontWeight: 500,
                }}>from</Typography>
                <Typography sx={{
                  fontSize: 16,
                  color: "black",
                  fontWeight: 700,
                }}>{insurance.iss}</Typography>
              </Box>
              <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-around", width: "40%",
                backgroundColor: customColors.lightGray, borderRadius: "16px", padding: "4px 0px"}}>
                <Typography sx={{
                  fontSize: 16,
                  color: "black",
                  fontWeight: 500,
                }}>to</Typography>
                <Typography sx={{
                  fontSize: 16,
                  color: "black",
                  fontWeight: 700,
                }}>{insurance.exp}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{display: "flex", flexDirection: "row", maxWidth: "540px"}}>
        <StyledButton text="Save Changes" type="submit" sx={{...styles.buttons}} />
      </Box>
    </Box>
    <Box sx={{}}>
      <Typography variant="subtitle1" sx={{color: customColors.darkGray,  ...styles.defaultMargin}}>Avatar</Typography>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        type="file"
        onChange={(e) => {
          handleFileSelect(e);
        }}
      />
      <label htmlFor="raised-button-file">
        {!selectedFile.image ?
          <Button variant="outlined" component="span" sx={{
            borderStyle: "dashed",
            borderColor: customColors.blue,
            color: customColors.black,
            width: "260px",
            height: "160px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            ...styles.defaultMargin
          }}>
            <Typography sx={{ fontSize: 16 }}>Upload file</Typography>
            <Typography sx={{ fontSize: 16 }}>no more 10Mb</Typography>
          </Button>
          :
          <CardMedia
            sx={{
              width: "260px",
              height: "160px",
            }}
            image={selectedFile.image}
          />
        }
      </label>
    </Box>
    </Box>
    )}
  </form>
  )
}