import { createTheme } from '@mui/material';
import { common, orange } from '@mui/material/colors';
import typography from './typography';
import palette from './palette';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    tablet: true;
  }
}

export const lightTheme = createTheme({
  typography,
  palette,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tablet: 1400,
    }
  },
  // palette: {
  //   primary: {
  //     main: common.black,
  //   },
  //   secondary: {
  //     main: common.white,
  //   },
  // },
});

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: common.black,
    },
    secondary: {
      main: common.white,
    },
  },
});