import { Box, Button, Typography } from '@mui/material';
import StyledButton from '../../UI/StyledButton';
import styles from '../../Dashboard/src/styles';
import { customColors } from '../../../theme/palette';
import landStyles from '../../LandingPage/src/styles';
import { useActions, useUser } from '../../../hooks/useHooks';
import { useState } from 'react';
import CardModal from './CardModal';
import { useNavigate } from 'react-router-dom';
import Balance from './Balance';
import WithdrawModal from './WithdrawModal';

const TEST_CARD = {
  first_name: ``,
  last_name: ``,
  routing_number: ``,
  account_number: ``,
  last_4: ``, //Last 4 digits of social security
  dob_day: ``,
  dob_month: ``,
  dob_year: ``,
  us_phone: ``,
  address1: ``,
  address2: ``,
  city: ``,
  state: ``,
  zip: ``,
  country: ``,
  last4: ``,
  brand: ``,
}

export default () => {
  const { userDraft } = useUser();
  const navigate = useNavigate();
  const { banks } = userDraft;
  const [isModalOpen, setModalOpen] = useState(false);
  const [isWithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  const [currentBalance, setCurrentBalance] = useState(0);
  const {clearUserDraft} = useActions();

  const lastCard = banks.length ? banks[banks.length - 1] : TEST_CARD;
  // lastCard.zip = creditcards.length ? lastCard.zip : userDraft.zip;
  // lastCard.name_oncard = creditcards.length ? lastCard.name_oncard : userDraft.first_name;
  return (
    <Box sx={{display: "flex", flexDirection: "column", width: "100%"}}>
      <CardModal modalTrigger={isModalOpen} setModalTrigger={setModalOpen} currentCard={lastCard} />
      <WithdrawModal modalTrigger={isWithdrawModalOpen} setModalTrigger={setWithdrawModalOpen} balance={currentBalance} />
      <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", ...landStyles.defaultMargin,
        ['@media (max-width:1000px)']: {
          flexDirection: "column",
        },
      }}>
        <Box sx={{display: "flex", flexDirection: "column", width: "80%"}}>
          <Balance setCurrentBalance={setCurrentBalance} setModalTrigger={setWithdrawModalOpen} isCard={!!banks.length}/>
          { banks.length ? <Box sx={{ ...styles.balance }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '80%',
              height: '40%',
              alignItems: 'center',
              minHeight: "130px",

            }}>
              <Typography sx={{ fontSize: 32 }}>{lastCard.brand}</Typography>
              <Typography variant='subtitle1'>
                •• {lastCard.last4}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%', height: '40%', minHeight: "60px", }}>
              <Typography color={customColors.primary} sx={{ fontSize: 12 }}>Address for sending receipts</Typography>
              <Typography variant='subtitle1'>{userDraft.address1}</Typography>
            </Box>
          </Box>
            :
            <StyledButton text="Add bank"
            sx={{height: "56px", width: "260px", maxWidth: "260px",}}
            onClick={() => setModalOpen(true)}
            />
          }
        </Box>
        <Box sx={{
          display: "flex", flexDirection: "column",
          ['@media (max-width:1000px)']: {
            width: "80%",
          },
        }}>
          <StyledButton fullWidth sx={{maxWidth: "100%",
            borderRadius: "16px",
            ['@media (max-width:1000px)']: {
              width: "50%",
              minWidth: "280px",
              marginTop: "20px"
            },
          }} text="Billing history"
          onClick={() => navigate('/billing-history')}
          />
          {banks.length ? <Button variant='outlined' fullWidth sx={{
            borderRadius: '16px', padding: '10px', color: customColors.black, marginTop: '20px',
            ['@media (max-width:1000px)']: {
              width: '50%',
              minWidth: '280px',
            },
          }}
          disabled
          onClick={() => setModalOpen(true)}>
            Change bank
          </Button> : null}
        </Box>
      </Box>
    </Box>
  )
}