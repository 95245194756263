export const AddressInfoKeys = [
  'country',
  'city',
  'state',
  'zip',
  'address1',
  'address2',
  'address_details',

];

export const ContactInfoKeys = [
  'country_code',
  'phone',
  'first_name',
  'middle_name',
  'last_name',
  'dob',
  'sex',
];