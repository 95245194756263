import NotificationTypes from './types';
import { UserService } from '../../api/services/user-service';
import { changeNotifiesFormat } from '../../utils/change-notifies-format';


export const getNotifications = () => async (dispatch: any) => {
  const userEmail = localStorage.getItem('email');
  if (userEmail) {
    try {
      const {status, ...notifies} = await UserService.getNotifications(userEmail);
      if (status === "200") {
        dispatch({
          type: NotificationTypes.SET_NOTIFICATIONS,
          payload: notifies
        });
        return notifies;
      } else {
        throw new Error(`User not found`);
      }
    } catch (e) {
      throw new Error(`Update error`);
    }
  }
}

export const updateNotifications = (body: any) => async (dispatch: any) => {
  const email = localStorage.getItem('email');
  if (email) {
    try {
      await UserService.updateNotifications(body, email);
      dispatch({
        type: NotificationTypes.UPDATE_NOTIFICATIONS_DRAFT,
        payload: body
      });
    } catch (e) {
      throw new Error(`Update error`);
    }
  }
}