export const SnackbarTypes = {
  SHOW_SUCCESS: 'snackbar/SHOW_SUCCESS',
  SHOW_ERROR: 'snackbar/SHOW_ERROR',
  CLEAR: 'snackbar/CLEAR',
};

export const SnackbarMessageType = {
  SUCCESS: 'success',
  ERROR: 'error',
};
