import { customColors } from '../../../theme/palette';
import typography from '../../../theme/typography';

export default {
  landingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%"
  },
  contentBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "5%",
    width: "80%",
    ['@media (max-width:1300px)']: {
      width: "96%",
    },
    ['@media (max-width:800px)']: {
      width: "98%",
    },
  },
  innerBox: {
    display: "flex",
    flexDirection: "column",
    minHeight: "700px",
    minWidth: "25%",
    maxWidth: "75%",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  innerBoxWithoutPadding: {
    display: "flex",
    flexDirection: "column",
    minHeight: "700px",
    minWidth: "390px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
    minWidth: "48px",
    minHeight: "48px"
  },
  expBox: {
    marginTop: "3%",
    width: "90%",
    background: "linear-gradient(270deg, #9428FF 0%, #435DFF 100%)",
    borderRadius: "40px",
  },
  expWithBackBox: {
    ['@media (max-width:1300px)']: {
      minHeight: "1000px",
      backgroundPosition: '350px 420px',
      alignContent: "flex-start",
      justifyContent: "center"
    },
    ['@media (max-width:1000px)']: {
      backgroundPosition: '250px 420px',
    },
    ['@media (max-width:900px)']: {
      backgroundPosition: '200px 420px',
    },
    ['@media (max-width:800px)']: {
      backgroundPosition: '200px 400px',
    },
    ['@media (max-width:700px)']: {
      backgroundSize: '600px',
      backgroundPosition: '100px 400px',
    },
    ['@media (max-width:500px)']: {
      backgroundPosition: '0px 400px',
    },
    display: "flex",
    width: "100%",
    backgroundPosition: '820px 100px',
    backgroundSize: '600px',
    backgroundRepeat: 'no-repeat',
    alignContent: "center",
    justifyContent: "flex-start",
    borderRadius: "40px",
  },
  weValueBox: {
    backgroundRepeat: 'no-repeat',
    borderRadius: "50px",
    backgroundSize: 'cover',
    backgroundPosition: '0px -200px',
    width: "100%",
    maxWidth: "100%",
    padding: "0px",
    paddingLeft: "0px",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    ['@media (max-width:1300px)']: {
      minHeight: "800px",
      backgroundPosition: '-300px -0px',
    },
    ['@media (max-width:1000px)']: {
      backgroundPosition: '-250px -0px',
    },
    ['@media (max-width:700px)']: {
      backgroundPosition: '-400px -0px',
    },
    ['@media (max-width:500px)']: {
      backgroundPosition: '-450px -0px',
    },
  },
  boxWithNumber: {
    display: "flex",
    flexDirection: "row",
    minWidth: "250px",
    alignItems: "center",
    marginRight: "5%",
    ['@media (max-width:1680px)']: {
      marginBottom: "5%",
    }
  },
  purpleNumber: {
    width: "50px",
    height: "50px",
    backgroundColor: customColors.purple,
    color: customColors.white,
    marginRight: "3%",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: 3
  },
  sidedContent: {
    width: "30%"
  },
  left: {

  },
  signUp: {
    color: customColors.black,
    backgroundColor: customColors.primary,
    "&:hover": {backgroundColor: customColors.secondary}
  },
  defaultMargin: {
    marginBottom: "5%",
  },
  createAnAcc: {
    maxWidth: "45%",
    minWidth: "150px",
    color: customColors.black,
    borderRadius: "10px",
    borderWidth: "2px",
    fontSize: "14px"
  },
  whatWeDoBox: {
    display: "flex",
    flexDirection: "row",
    width: "60%",
    flexWrap: "wrap",
    alignItems: "center"
  },
  whatWeDoBoxTablet: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  smallerDoBox: {
    backgroundColor: customColors.darkPurple,
    color: customColors.white,
    height: "40%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "5%",
    borderRadius: "10px",
    width: "15%",
    minWidth: "140px"
  },
  doBox: {
    border: `2px solid ${customColors.darkPurple}`,
    color: customColors.black,
    height: "50%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "5%",
    borderRadius: "10px",
    width: "15%",
    minWidth: "140px"
  },
  retailBox: {
    backgroundColor: customColors.green,
    borderRadius: "7px",
    color: customColors.white,
    display: "flex",
    justifyContent: "center",
    height: "80%",
    width: "10%",
    minWidth: "150px",
    flexDirection: "column",
    alignItems: "center",
  },
  ceoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10%"
  },
  twoSidedPics: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: "30px",
    ['@media (max-width:600px)']: {
      flexDirection: "column",
    },
  },
  faqBox: {
    color: customColors.black,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "10%",
    maxHeight: "340px",
    maxWidth: "55%",
    borderRadius: "7px",
    flexDirection: "column"
  },
  faqTabletBox: {
    color: customColors.black,
    padding: "5%",
    borderRadius: "7px",
    flexDirection: "column"
  },
  joinUsBox: {
    background: `linear-gradient(270deg, #435DFF 0%, #9428FF 100%)`,
    color: customColors.white,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "12%",
    paddingLeft: "20%",
    paddingRight: "20%",
    height: "100%",
    maxWidth: "60%",
    borderRadius: "40px",
    flexDirection: "column",
    ['@media (max-width:760px)']: {
      width: "100%",
      marginTop: "20px"

    },
  },
  testimonials: {
    background: "linear-gradient(270deg, #9428FF 0%, #435DFF 100%)",
    justifyContent: "space-around",
    alignItems: "center",
    minWidth: "100%",
    width: "100%",
    borderRadius: "40px",
    padding: "0px",
    paddingLeft: "0px",
    ['@media (max-width:760px)']: {
      background: "white",
      color: "black"
    },
  },
  testsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%"
  },
  sliderButtons: {
    borderRadius: "100%",
    backgroundColor: customColors.primary,
    color: customColors.white,
    width: "50px",
    height: "50px",
    '&:hover': {
      backgroundColor: customColors.secondary,
    },
    ['@media (max-width:600px)']: {
      minWidth: "40px",
      minHeight: "40px",
      maxWidth: "40px",
      maxHeight: "40px",
      padding: "0px"
    },
  },
  reviewBox: {
    padding: "30px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "680px",
    height: "380px",
    marginLeft: "50px",
    marginRight: "50px",
    backgroundColor: customColors.white,
    borderRadius: "20px",
    ['@media (max-width:1600px)']: {
      width: "550px",
    },
    ['@media (max-width:1300px)']: {
      width: "500px",
      height: "100%"
    },
    ['@media (max-width:800px)']: {
      marginLeft: "10px",
      marginRight: "10px",
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
      height: "500px",
    },
    ['@media (max-width:460px)']: {
      width: "150px",
      minWidth: "150px",
      maxWidth: "150px",
    },
  },

  resizeH1: {
    ['@media (max-width:1300px)']: {
      fontSize: 56
    },
    ['@media (max-width:800px)']: {
      fontSize: 32
    },
  },
  resizeH2: {
    ['@media (max-width:1300px)']: {
      fontSize: 28
    },
    ['@media (max-width:800px)']: {
      fontSize: 24
    },
  },
  resizeH3: {
    ['@media (max-width:1300px)']: {
      fontSize: 16
    },
    ['@media (max-width:800px)']: {
      fontSize: 18
    },
  },
  bckGroundRect: {
    width: "784px",
    height: "593px",
    borderRadius: "24px",
    backgroundColor: customColors.primary,
    position: "absolute",
    zIndex: -1,
    ['@media (max-width:1500px)']: {
      display: "none"
    },
  },
  rectRight: {
    left: "85%",
  },
  rectLeft: {
    right: "85%"
  },
  aboutRects: {
    ['@media (max-width:1680px)']: {
      display: "none"
    },
  },
}