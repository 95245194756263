import styles from '../src/styles';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import React from 'react';
import StyledButton from '../../UI/StyledButton';
import { customColors } from '../../../theme/palette';
import { breakPoints } from '../src/breakpoints';
import { useNavigate } from 'react-router-dom';

const breaks = breakPoints(800, 600, 600, 45, 450);

export default () => {
  const navigate = useNavigate();
  return (
    <Box sx={{...styles.contentBox, alignItems: "center", ['@media (max-width:800px)']: {
        flexDirection: "column",
      }}} className="You just need">
      <CardMedia sx={{
        height: "555px",
        width: "555px",
        borderRadius: "12px",
        ['@media (max-width:960px)']: {
          height: "490px",
          width: "490px",
        },
        ['@media (max-width:875px)']: {
          height: "400px",
          width: "400px",
        },
        ...breaks
      }} image={require('../src/R1.png')}/>
      <Box sx={{...styles.innerBox, marginLeft: "100px", ['@media (max-width:800px)']: {
          justifyContent: "flex-start",
          marginTop: "30px",
          maxHeight: "400px",
          minHeight: "400px"
        }}}>
        <Typography variant="h2" sx={{fontWeight: "500", ...styles.resizeH2}}>
          YOU JUST
        </Typography>
        <Typography variant="h2" sx={{...styles.defaultMargin, fontWeight: "500", ...styles.resizeH2}}>
          NEED
        </Typography>
        <Box sx={{display: "flex", flexDirection: "row", ...styles.defaultMargin, ['@media (max-width:1680px)']: {
            flexDirection: "column"
          },}}>
          <Box sx={{...styles.boxWithNumber}}>
            <Typography variant="subtitle1" sx={{...styles.purpleNumber, fontWeight: "bold"}}>1</Typography>
            <Typography variant="subtitle1">Driver's license</Typography>
          </Box>
          <Box sx={{...styles.boxWithNumber}}>
            <Typography variant="subtitle1" sx={{...styles.purpleNumber, fontWeight: "bold"}}>2</Typography>
            <Box>
              <Typography variant="subtitle1">To be over</Typography>
              <Typography variant="subtitle1" sx={{fontWeight: "bold"}}>18 years of age</Typography>
            </Box>
          </Box>
          <Box sx={{...styles.boxWithNumber}}>
            <Typography variant="subtitle1" sx={{...styles.purpleNumber, fontWeight: "bold"}}>3</Typography>
            <Typography variant="subtitle1">A car</Typography>
          </Box>
        </Box>
        <StyledButton sx={{...styles.signUp, minWidth: "150px", minHeight: "50px"}} text="Start earning"
                      onClick={() => navigate('/registration')}
        />
      </Box>
    </Box>
  )
}