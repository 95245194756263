import { Box, Button, CardMedia, MenuItem, Modal, TextField, Typography } from '@mui/material';
import styles from "../../Dashboard/src/styles";
import { useState } from 'react';
import dashStyles from '../../Dashboard/src/styles';
import { customColors } from '../../../theme/palette';
import { UserService } from '../../../api/services/user-service';
import { useActions } from '../../../hooks/useHooks';

export default ({modalTrigger, setModalTrigger, balance}: any) => {
  const [amount, setAmount] = useState('');
  const {showErrorMessage, showSuccessMessage} = useActions();

  const handleSubmit = async () => {
    const email = localStorage.getItem('email');
    if (balance >= Number(amount) && email) {
      const {status} = await UserService.payOut(email, amount.length ? amount : balance);
      if (status === "200") {
        setModalTrigger(false);
        showSuccessMessage();
      } else {
        showErrorMessage();
      }
    }
  };

  return (
    <Modal
      open={modalTrigger}
      onClose={() => setModalTrigger(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
      <Box sx={{ ...styles.modal, justifyContent: "center", minHeight: "300px", paddingTop: "10px", paddingBottom: "10px", width: "30%"}}>
        <Box sx={{...styles.insideModalBox, minHeight: "90%",}}>
          <Typography variant="h2" sx={{marginBottom: "25px"}}>
            Withdraw money
          </Typography>
          <Typography variant="subtitle1" sx={{width: "50%", marginBottom: "25px"}}>
            Specify the amount of the transfer to the bank account:
          </Typography>
          <TextField label="Amount" type="number" variant="outlined" sx={{...styles.input, width: "70%", marginBottom: "25px"}} value={amount}
                     onChange={e => setAmount(e.target.value)}
          />
          <Box sx={{display: "flex", justifyContent: "center", flexDirection: "row", width: "100%"}}>
            <Button sx={{...dashStyles.button, ...dashStyles.blackButton, height: "56px",
              "&:disabled": {
                color: customColors.gray
              }}}
              disabled={
                Number(amount) >= balance
              }
              onClick={handleSubmit}
            >
              {
                amount.length ?
                  `Transfer - $ ${amount}` :
                  `Or transfer all - $ ${balance}`
              }
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}