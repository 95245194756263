import DashboardHeader from '../Header/DashboardHeader';
import styles from '../Dashboard/src/styles';
import { Box, Button, Typography } from '@mui/material';
import { useAuthRedirect } from '../../utils/use-auth';
import { customColors } from '../../theme/palette';
import { useCallback, useEffect, useState } from 'react';
import { UserService } from '../../api/services/user-service';
import Loader from '../LandingPage/components/Loader';
import { parseUTC } from '../../utils/utc-to-date';

const InitSummaryState = {
  reward_30days: 0,
  reward_90days: 0,
  reward_total: 0,
  count_30days: 0,
  count_90days: 0,
  count_total: 0,
  delivery_reward_30days: 0,
  delivery_reward_90days: 0,
  delivery_reward_total: 0,
  delivery_count_30days: 0,
  delivery_count_90days: 0,
  delivery_count_total: 0,
  return_reward_30days: 0,
  return_reward_90days: 0,
  return_reward_total: 0,
  return_count_30days: 0,
  return_count_90days: 0,
  return_count_total: 0,
  tryon_reward_30days: 0,
  tryon_reward_90days: 0,
  tryon_reward_total: 0,
  tryon_count_30days: 0,
  tryon_count_90days: 0,
  tryon_count_total: 0,
}


export default () => {
  useAuthRedirect();
  const [transacts, setTransacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [limit, setLimit] = useState(10);
  const [summary, setSummary]: any = useState(InitSummaryState);
  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) {
      UserService.getTransactions(email, 0)
        .then((res: any) => {
          UserService.getTransactionSummary(email)
            .then((result: any) => {
              const {status, ...summaryInfo} = result;
              if (status === "200" && res.data.status === "200" && res.data.message === "no_transactions") {
                setLoading(false);
              } else if (res.data.length && status === "200") {
                setSummary((prevState: any) => ({...prevState, ...summaryInfo}));
                setTransacts(res.data);
                setShowMore(res.showMore);
                setLoading(false);
              }
            });
        })
    }
  }, []);

  const handleShowMore = useCallback(() => {
    setLoading(true);
    const email = localStorage.getItem('email');
    const newLimit = limit + 10;
    if (email) {
      UserService.getTransactions(email, limit, newLimit)
        .then((res: any) => {
          if (res.data.status === "200" && res.data.message === "no_transactions") {
            setLoading(false);
          } else if (res.data.length) {
            setTransacts((prevState: any) => ({
              ...prevState,
              ...res.data
            }));
            setShowMore(res.showMore);
            setLimit(newLimit);
            setLoading(false);
          }
        })
    }
  }, [limit]);

  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
      <DashboardHeader />
      <Box sx={{...styles.box}}>
        {!loading ? <Box sx={{...styles.contentBox, width: "80%"}}>
          <Typography variant="h2" sx={{...styles.defaultMargin}}>
            Billing History
          </Typography>
          <Box sx={{
            display: "flex", flexDirection: "row", ...styles.defaultMargin,
          }}>
            <Box sx={{
              display: "flex", flexDirection: "row", width: "544px",
              border: `2px solid ${customColors.lightGray}`, borderRadius: "32px",
              marginRight: "10px", height: "152px",
            }}>
              <Box sx={{width: "70%", display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "5%"}}>
                <Box sx={{width: "60%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                  {
                    summary.delivery_count_30days ?
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", minHeight: "35px"}}>
                        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "70%"}}>Delivery</Typography>
                        <Typography variant="subtitle1" sx={{}}>{summary.delivery_count_30days} • $ {summary.delivery_reward_30days}</Typography>
                      </Box> : null
                  }
                  {
                    summary.return_count_30days ?
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", minHeight: "35px"}}>
                        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "70%"}}>Return</Typography>
                        <Typography variant="subtitle1" sx={{}}>{summary.return_count_30days} • $ {summary.return_reward_30days}</Typography>
                      </Box> : null
                  }
                  {
                    summary.tryon_count_30days ?
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", minHeight: "35px"}}>
                        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "70%"}}>Tryon</Typography>
                        <Typography variant="subtitle1" sx={{}}>{summary.tryon_count_30days} • $ {summary.tryon_reward_30days}</Typography>
                      </Box> : null
                  }
                </Box>
              </Box>
              <Box sx={{width: "30%", backgroundColor: customColors.lightGray, borderTopRightRadius: "32px", borderBottomRightRadius: "32px",
                display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <Typography variant="subtitle1" sx={{color: customColors.gray}}>
                  Last month
                </Typography>
                <Typography sx={{fontSize: 24}}>
                  {summary.count_30days}
                </Typography>
                <Typography sx={{fontSize: 24}}>
                  {summary.reward_30days}
                </Typography>

              </Box>
            </Box>
            <Box sx={{
              display: "flex", flexDirection: "row", width: "544px",
              border: `2px solid ${customColors.lightGray}`, borderRadius: "32px",
              height: "152px",
            }}>
              <Box sx={{width: "70%", display: "flex", flexDirection: "column", justifyContent: "center", paddingLeft: "5%"}}>
                <Box sx={{width: "60%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                  {
                    summary.delivery_count_90days ?
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", minHeight: "35px"}}>
                        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "70%"}}>Delivery</Typography>
                        <Typography variant="subtitle1" sx={{}}>{summary.delivery_count_90days} • $ {summary.delivery_reward_90days}</Typography>
                      </Box> : null
                  }
                  {
                    summary.return_count_90days ?
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", minHeight: "35px"}}>
                        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "70%"}}>Return</Typography>
                        <Typography variant="subtitle1" sx={{}}>{summary.return_count_90days} • $ {summary.return_reward_90days}</Typography>
                      </Box> : null
                  }
                  {
                    summary.tryon_count_90days ?
                      <Box sx={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", minHeight: "35px"}}>
                        <Typography variant="subtitle1" sx={{color: customColors.gray, width: "70%"}}>Tryon</Typography>
                        <Typography variant="subtitle1" sx={{}}>{summary.tryon_count_90days} • $ {summary.tryon_reward_90days}</Typography>
                      </Box> : null
                  }
                </Box>
              </Box>
              <Box sx={{width: "30%", backgroundColor: customColors.lightGray, borderTopRightRadius: "32px", borderBottomRightRadius: "32px",
                display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <Typography variant="subtitle1" sx={{color: customColors.gray}}>
                  Last month
                </Typography>
                <Typography sx={{fontSize: 24}}>
                  {summary.count_90days}
                </Typography>
                <Typography sx={{fontSize: 24}}>
                  {summary.reward_90days}
                </Typography>

              </Box>
            </Box>
          </Box>
          <Box sx={{
            display: "flex", width: "100%", flexDirection: "column"
          }}>
            <Box sx={{
              display: "flex", width: "100%", flexDirection: "row",
              height: "48px", alignItems: "center",
              /* delete for rows */ borderBottom: `1px solid ${customColors.lightGray}`
            }}>
              <Typography variant="subtitle1" sx={{width: "10%", color: customColors.darkGray, display: "flex", justifyContent: "center"}}>
                №
              </Typography>
              <Typography variant="subtitle1" sx={{width: "25%", color: customColors.darkGray}}>
                Date
              </Typography>
              <Typography variant="subtitle1" sx={{width: "25%", color: customColors.darkGray}}>
                Type
              </Typography>
              <Typography variant="subtitle1" sx={{width: "25%", color: customColors.darkGray}}>
                Price
              </Typography>
            </Box>
            {!transacts.length ?
              <Box sx={{width: "100%", textAlign: "center", marginTop: "20px"}}>
                <Typography variant="h3">No transactions yet</Typography>
              </Box>
              : null
            }
            {
              transacts.map(((transaction: any, index) => {
                const bckColor = index % 2 ? {
                  backgroundColor: customColors.lightGray
                } : {};
                const isMinus = transaction.amount[0] === '-';
                return (
                  <Box sx={{
                    display: "flex", width: "100%", flexDirection: "row",
                    height: "48px", alignItems: "center",
                    borderBottom: `1px solid ${customColors.lightGray}`,
                    ...bckColor, borderRadius: "32px"
                  }}>
                    <Typography variant="subtitle1" sx={{width: "10%", display: "flex", justifyContent: "center"}}>
                      {index + 1}
                    </Typography>
                    <Typography variant="subtitle1" sx={{width: "25%",}}>
                      {parseUTC(transaction.created_at)}
                    </Typography>
                    <Typography variant="subtitle1" sx={{width: "25%",}}>
                      {transaction.type}
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "row", width: "25%", }}>
                      {
                        isMinus ?
                          <Typography variant="h4" sx={{color: "red"}}>
                            -
                          </Typography>
                          :
                          <Typography variant="h4" sx={{color: "green"}}>
                            +
                          </Typography>
                      }
                      <Typography variant="subtitle1">
                        $ {!isMinus ? transaction.amount : transaction.amount.slice(1, transaction.amount.length)}
                      </Typography>
                    </Box>
                  </Box>
                )}))
            }
            {showMore ?
              <Box sx={{width: "100%", textAlign: "center", marginTop: "20px"}}>
                <Button sx={{backgroundColor: "black", color: "white", borderRadius: "32px",
                  paddingLeft: "10px", paddingRight: "10px",
                  minHeight: "50px", minWidth: "150px",
                  "&:hover": {
                    backgroundColor: "black", color: "white",
                  }}}
                        onClick={handleShowMore}
                >Show More</Button>
              </Box>
              : null
            }
          </Box>
        </Box> : <Loader />}
      </Box>
    </Box>
  )
}