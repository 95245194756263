import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/authContext';

export function useAuth() {
  return useContext(AuthContext);
}

export function useAuthRedirect(protect = true) {
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (protect && !token) {
      navigate('/login');
    }

    if (!protect && token) {
      navigate('/profile');
    }
  }, [protect, navigate, token]);
}
