import styles from '../Dashboard/src/styles';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useActions, useSupportCurrentTicket } from '../../hooks/useHooks';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '../LandingPage/components/Loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { customColors } from '../../theme/palette';
import StyledButton from '../UI/StyledButton';
import { SupportService } from '../../api/services/support-service';
import { parseUTC } from '../../utils/utc-to-date';
import DashboardHeader from '../Header/DashboardHeader';

export default () => {
  const {setCurrentTicket} = useActions();
  const currentTicket = useSupportCurrentTicket();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (!Object.keys(currentTicket).length) {
      const id = searchParams.get("id");
      if (id) {
        SupportService.getTicketById(id)
          .then((resp) => {
            const ticket = resp[0];
            setCurrentTicket(ticket);
            setLoading(false);
          })
      } else {
        navigate('/support');
      }
    } else {
      setLoading(false);
    }
  }, [])

  const userEmail = localStorage.getItem('email');

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const resp = await SupportService.addTicketMessage({
      ticket_id: currentTicket.ticket_id,
      message,
    });
    if (resp.status === "200" && resp.message === "message_successfully_added") {
      SupportService.getTicketById(currentTicket.ticket_id)
        .then((response) => {
          const ticket = response[0];
          setMessage('');
          setCurrentTicket(ticket);
        })
    }
  }

  return (
  <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
    <DashboardHeader />
    <Box sx={{ ...styles.box, justifyContent: "flex-start" }}>
      <Box sx={{width: "90%", }}>
        {!loading ?
        <Box sx={{ ...styles.contentBox, width: '50%' }}>
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "30px"}}>
            <Button sx={{...styles.button, ...styles.whiteButton, width: "52px", height: "40px", marginRight: "30px",}}
                    onClick={() => navigate('/support')}
            >
              <ArrowBackIcon />
            </Button>
            <Typography variant="h2" sx={{marginRight: "30px"}}>Ticket</Typography>
            <Typography variant="h2" sx={{color: customColors.gray}}>{currentTicket.ticket_count}</Typography>
          </Box>
          <Box sx={{display: "flex", flexDirection: "column", width: "100%", marginBottom: "30px"}}>
            {
              currentTicket.messages.map((mess: any) => {
                const isUser = (userEmail === mess.author_email);
                return (
                  <Box sx={isUser ? {...styles.ticketMessage, ...styles.userMessage} : {...styles.ticketMessage,}} key={`${mess.author_id} ${mess.created_at}`}>
                    <Box sx={{display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-between", marginBottom: "20px", alignItems: "center"}}>
                      <Typography variant="subtitle1">
                        {
                          isUser ? 'You' : 'Support response'
                        }
                      </Typography>
                      <Typography sx={{color: customColors.darkGray}}>
                        {
                          parseUTC(mess.created_at)
                        }
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1" sx={{width: "90%"}}>
                      {
                        mess.message
                      }
                    </Typography>
                  </Box>
                )
              })
            }
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              multiline={true}
              rows={10}
              required
              label="User message"
              fullWidth
              variant="outlined"
              sx={{
                marginBottom: "30px",
                height: "130px",
                ...styles.input,
                fontSize: "16px",
                borderRadius: "16px",
              }}
              inputProps={{
                style: {
                  height: "130px",
                  fontSize: "16px",
                }
              }}
              onChange={(e) =>setMessage(e.target.value)}
            />
            <StyledButton text="Send message" sx={{
              minWidth: "240px",
              marginTop: "50px",
              minHeight: "64px",
            }} type="submit" />
          </form>
        </Box>
        : <Loader />}
      </Box>
    </Box>
  </Box>
  )
}