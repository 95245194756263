import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import rootActions from '../redux/rootActions';


export const useSession = () => useSelector((state: any) => state.session);
export const useUser = () => useSelector((state: any) => state.user);
export const useNotifications = () => useSelector((state: any) => state.notifications);
export const useOrders = () => useSelector((state: any) => state.dashboard.orders);
export const useShowMore = () => useSelector((state: any) => state.dashboard.showMore);
export const useSupportTickets = () => useSelector((state: any) => state.support.tickets);
export const useSupportCurrentTicket = () => useSelector((state: any) => state.support.currentTicket);



export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(rootActions, dispatch);
}