import styles from '../src/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { customColors } from '../../../theme/palette';

export default ({faq: accordions, phone}: any) => (
  <Box sx={{...styles.contentBox, justifyContent: "center", display: { xs: 'flex', tablet: 'none' }}}>
      <Box sx={{...styles.faqTabletBox}}>
        <Typography variant="h2" sx={{...styles.defaultMargin, fontWeight: "bold", textAlign: "center", ...styles.resizeH2}}>
          FAQ
        </Typography>
        <Box>
          {accordions.map((qna: any, index: any) => (
            <Accordion key={index} sx={{borderRadius: "15px", backgroundColor: customColors.lightGray, marginBottom: "5px", boxShadow: "0px 0px",
              '&:before': {
                display: 'none',
              }, "&.Mui-expanded": {
              backgroundColor: customColors.white
            }}} square>
              <AccordionSummary
                expandIcon={(
                  <Box sx={{padding: "1px", backgroundColor: customColors.primary, borderRadius: "30%", width: "24px", height: "24px"}}>
                    <ExpandMore />
                  </Box>
                )}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography sx={{fontSize: 20}}>{qna.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {qna.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
        <Typography variant="h4" sx={{...styles.defaultMargin, marginTop: "4%"}}>
          Where can I go if I have more questions?
        </Typography>
        <Typography variant="subtitle2" sx={{...styles.defaultMargin}}>
          If you'd like to speak to our support, you can reach us via online chat in your dashboard or call us Monday-Friday:
        </Typography>
        <Box sx={{display: "flex", alignItems: "center",}}>
          <Typography variant="subtitle1">
            United States:
          </Typography>
          <Link href={`tel:+1 ${phone}`}
                sx={{color: "black", marginLeft: "10px",
                  display: "flex", alignItems: "center", minHeight: "28px"
                }}
          >+1 {phone}</Link>
        </Box>
      </Box>
  </Box>
);