import { Icon } from '@mui/material';
import { ReactComponent as Logo } from './Logo.svg';
const styles = {
  imageIcon: {
    display: 'flex',
    height: 'inherit',
    width: 'inherit',
  },
  iconRoot: {
    textAlign: 'center',
  }
};

export default (props: any) => {
  return (
      <Logo style={{...styles.imageIcon}}/>
)}