import { Box, CardMedia, MenuItem, Modal, TextField, Typography } from '@mui/material';
import styles from "../../Dashboard/src/styles";
import { useState } from 'react';
import { UserService } from '../../../api/services/user-service';
import { useActions } from '../../../hooks/useHooks';
import StyledButton from '../../UI/StyledButton';

export default ({modalTrigger, setModalTrigger, phone, country_code, isRegister, handleRegisterSubmit}: any) => {
  const [code, setCode] = useState(``);
  const {showErrorMessage, showSuccessMessage} = useActions();
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const email = localStorage.getItem('email');
    if (email) {
      const {status, message} = await UserService.verifyPhone(email, code, phone, country_code);
      if (status === "200" && message !== "wrong_code" && message !== "phone_not_exist") {
        setModalTrigger(false);
        showSuccessMessage();
        if (isRegister) {
          handleRegisterSubmit();
        }
      } else {
        switch (message) {
          case "wrong_code": {
            showErrorMessage("Wrong code");
            break;
          }
          default: {
            showErrorMessage();
            break;
          }
        }
      }
    }
  };
  return (
    <Modal
      open={modalTrigger}
      onClose={() => setModalTrigger(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
      <Box sx={{ ...styles.modal, minHeight: "350px", width: "40%", justifyContent: "center"}}>
        <Box sx={{...styles.insideModalBox, minHeight: "90%", }}>
          <form onSubmit={handleSubmit} >
            <Typography variant="h2" sx={{...styles.defaultMargin, textAlign: "center"}}>
              Verify phone
            </Typography>
            <TextField required fullWidth label="Verification code" variant="outlined" type="number" sx={{ ...styles.defaultMargin, marginTop: "15px", ...styles.input}} value={code}
                       onChange={e => setCode(e.target.value)}/>
            <StyledButton text="Verify" type="submit" sx={{marginTop: "20px", minWidth: "200px", }}
                          disabled={!code.length}
            />
          </form>
        </Box>
      </Box>
    </Modal>
  )
}