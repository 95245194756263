import { Box, Fab, Toolbar, useScrollTrigger, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './src/styles';
import WeValue from './components/WeValue';
import YouAreTheBoss from './components/YouAreTheBoss';
import Tryouts from './components/Tryouts';
import WhoWeAre from './components/WhoWeAre';
import Testimonials from './components/Testimonials';
import QNA from './components/QNA';
import JoinUs from './components/JoinUs';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ScrollTop from '../UI/ScrollTop';
import QNATablet from './components/QNATablet';
import YouDrive from './components/YouDrive';
import WeWorkWith from './components/WeWorkWith';
import { customColors } from '../../theme/palette';
import { LandingService } from '../../api/services/lading-service';
import { LpDataParser } from './src/lpDataParser';
import Loader from './components/Loader';
import Header from '../Header/Header';

export default () => {
  const [loading, setLoading] = useState(true);
  const [tests, setTests]: any = useState([]);
  const [faq, setFaq]: any = useState([]);
  const [phone, setPhone] = useState(``);
  const [links, setLinks]: any = useState([]);
  useEffect (() => {
    (async () => {
      try {
        const data = await LandingService.getLanding();
        if (data) {
          const {testimonials, faq: parsedFaq, telephone, appLinks} = LpDataParser(data);
          setTests(testimonials);
          setFaq(parsedFaq);
          setPhone(telephone);
          setLinks(appLinks);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
      }
    })()
  }, []);
  return (
    <Box sx={{display: "flex", alignItems: "center", flexDirection: "column", width: "100%", height: "100%", minHeight: "100%"}}>
      <Header isTests={!loading && tests.length} />
    <Box sx={{...styles.landingBox}}>
      <WeValue appLinks={links} />
      <YouAreTheBoss />
      <YouDrive />
      <WeWorkWith />
      <Tryouts />
      <WhoWeAre />
      {!loading ? (tests.length ? <Testimonials tests={tests}/> : null) : <Loader />}
      {(!loading && faq.length) ? <Box sx={{width: "100%", height: "0px"}} className="FAQ"/> : null}
      {!loading ? (faq.length ? <QNA faq={faq} phone={phone}/> : null) : <Loader />}
      {!loading ? (faq.length ? <QNATablet faq={faq} phone={phone}/> : null) : <Loader />}
      <JoinUs />
      <ScrollTop>
        <Fab color="primary" size="large" aria-label="scroll back to top" sx={{height: "94px", width: "94px", "&:hover": {backgroundColor: customColors.secondary}}}>
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Box>
    </Box>
  )
};