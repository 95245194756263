import { InitialNotifiesState } from './constants';
import NotificationTypes from './types';

const initialState = InitialNotifiesState;

const changeField = (draft: any, fieldName: any, value: any) => ({
  ...draft,
  [fieldName]: value,
});

export default (state = initialState, action: any = "UPDATE_NOTIFICATIONS_DRAFT") => {
  const newState = { ...state };

  switch (action.type) {
    case NotificationTypes.UPDATE_NOTIFICATIONS_DRAFT:
      return {
        ...state,
        ...action.payload
      };

    case NotificationTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload
      };

    default:
      return newState;
  }
};