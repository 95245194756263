import api from '../api';
import { updateUserBody } from '../../utils/update-user-body';
import axios from 'axios';
import { apiRootUrl } from '../constants';

export class UserService {

  static async getQuestionnaire (email: string) {
    const URL = `/driver/getQuestionnaire`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getTransactionSummary (email: string ) {
    const URL = `/driver/getRewardsSummary`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }


  static async verifyPhone(email: string, code: string, phone: string, country_code: string) {
    const URL = `/verifyPhone`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('code', code);
    formData.append('phone', phone);
    formData.append('country_code', country_code);
    const {data} = await api.post(URL, formData);
    return data;
  }


  static async getProfile (email: string) {
    const URL = `/driver/getProfile`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async updateUser(body: any, email: string) {
    const {address: addressBody, phone: phoneBody, contact: contactBody} = updateUserBody(body);
    const address = Object.entries(addressBody);
    const phone = Object.entries(phoneBody);
    const contact = Object.entries(contactBody);
    let isError = false;
    if (address.length) {
      const formData = new FormData();
      formData.append('email', email);
      const URL = '/addAddress';
      for (const [key, value] of address) {
        formData.append(key, value);
      }
      try {
        const resp = await api.post(URL, formData);
      } catch (e) {
        isError = true;
      }
      // smth with resp here
    }
    if (phone.length) {
      const formData = new FormData();
      formData.append('email', email);
      const URL = '/addPhone';
      for (const [key, value] of phone) {
        formData.append(key, value);
      }
      try {
        const resp = await api.post(URL, formData);
      } catch (e) {
        isError = true;
      }
      // smth with resp here
    }
    if (contact.length) {
      const formData = new FormData();
      formData.append('email', email);
      const URL = '/addContact';
      for (const [key, value] of contact) {
        formData.append(key, value);
      }
      try {
        const resp = await api.post(URL, formData);
      } catch (e) {
        isError = true;
      }
      // smth with resp here
    }
    if (isError) {
      throw new Error('Error');
    }
  }

  static async addPhone(body: any, email: string) {
    const phone: any = Object.entries(body);
    const formData = new FormData();
    formData.append('email', email);
    const URL = '/addPhone';
    for (const [key, value] of phone) {
      formData.append(key, value);
    }
    try {
      const resp = await api.post(URL, formData);
    } catch (e) {
      throw new Error();
    }
  }

  static async getNotifications(email: string) {
    const URL = `/getNotificationsStatus?email=${email}`;
    const {data} = await api.get(URL);
    return data;
  }

  static async updateNotifications(body: any, userEmail: string) {
    const URL = '/updateNotifications';
    const token = localStorage.getItem('token');
    const settingsArray = Object.entries(body);
    const settings: any = {};
    settingsArray.map(([key, value]: any) => {
      const {email, txt, push} = value;
      settings[key] = {
        email,
        txt,
        push
      }
    });
    const reqBody = {
      email: userEmail,
      settings,
    };
    const {data} = await axios.post(apiRootUrl + URL, reqBody, {
      headers: {
        token: token ? token : '',
        "Content-Type": 'application/json'
      }
    });
    return data;
  }

  static async getReturnReasonsList(email: string) {
    const URL = `/client/getReturnReasonsList`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getListOfAllRetailers(email: string) {
    const URL = `/client/getListOfAllRetailers`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getOneTimeApiKey(email: string) {
    const URL = `/client/generateOneTimeApiKey`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async createReturn(email: string, body: any) {
    const URL = `/ecommerce/createOrder`;
    const formData = new FormData();
    const apiKey = await UserService.getOneTimeApiKey(email);
    formData.append('email', email);
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      formData.append(key, value);
    }
    const {status, data} = await axios.post(apiRootUrl + URL, formData, {
      headers: {
        "Content-Type":  "multipart/form-data",
        apikey: apiKey
      }
    });
    return data;
  }

  static async addCard(email: string, body: any) {
    const URL = `/driver/addPayOutBank`;
    const formData = new FormData();
    formData.append('email', email);
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      formData.append(key, value);
    }
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async addVehicle(email: string, vin: string, plate: string, plateState: string) {
    const URL = `/driver/addVehicle`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('vin', vin);
    formData.append('plate', plate);
    formData.append('plate_state', plateState);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getBackgroundCheckStatus(email: string) {
    const URL = `/driver/getBackgroundCheckStatus`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async setBackgroundCheckStatusPending(email: string) {
    const URL = `/driver/setBackgroundCheckStatusPending`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async addPolicy(email: string, body: any) {
    const URL = `/driver/addPolicy`;
    const formData = new FormData();
    formData.append('email', email);
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      formData.append(key, value);
    }
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async setQnaAnswers(email: string, body: any) {
    const URL = `/driver/setQuestionnaireAnswer`;
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      const { answer } = value;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('answer_id', answer);
      const {data} = await api.post(URL, formData);
      if (data.status !== "200") {
        return data;
      }
    }
    return {
      status: "200",
      data: "ok"
    }
  }

  static async getTransactions (email: string, skip: number, limit = 10, ) {
    const URL = `/getTransactions`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('limit', `${limit + 1}`);
    formData.append('skip', `${skip}`);
    const {data} = await api.post(URL, formData);
    const result = {
      data,
      showMore: data.length > limit
    }
    return result;
  }

  static async getSchedule(email: string) {
    const URL = `/driver/getSchedule`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async addSchedule(email: string, body: any) {
    const URL = `/driver/addSchedule`;
    const formData = new FormData();
    formData.append('email', email);
    const bodyData: any = Object.entries(body);
    for (const [key, value] of bodyData) {
      formData.append(key, value);
    }
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async getBalance(email: string) {
    const URL = `/ecommerce/getBalance`;
    const formData = new FormData();
    formData.append('email', email);
    const {data} = await api.post(URL, formData);
    return data;
  }

  static async payOut(email: string, amount: string) {
    const URL = `/driver/payOut`;
    const formData = new FormData();
    formData.append('email', email);
    formData.append('amount', `${amount}`);
    const {data} = await api.post(URL, formData);
    return data;
  }

}
