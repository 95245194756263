import { SnackbarTypes, SnackbarMessageType } from './types';
import { InitialSnackbarState } from './constants';

const reducer = (state = InitialSnackbarState, action: any) => {
  switch (action.type) {
    case SnackbarTypes.SHOW_SUCCESS:
      return {
        type: SnackbarMessageType.SUCCESS,
        open: true,
        message: action.payload,
      };

    case SnackbarTypes.SHOW_ERROR:
      return {
        type: SnackbarMessageType.ERROR,
        open: true,
        message: action.payload,
      };

    case SnackbarTypes.CLEAR:
      return InitialSnackbarState;

    default:
      return state;
  }
};

export default reducer;
